import classNames from 'classnames';
import { ReactNode } from 'react';
import './index.scss';

interface ILayout {
  sidebar?: ReactNode
  header?: ReactNode
  children?: ReactNode
  className?: string
}

function Layout({
  sidebar,
  header,
  children,
  className
}: ILayout) {
  return (
    <div className="layout-container">
      <div className="z-[100]">
        {sidebar}
      </div>
      <div className={classNames('fixed flex bg-[#1f2028] w-[100vw] h-[100vh]', { 'pt-[90px]': header }, { 'pl-[83px]': sidebar }, className)}>
        {
          header && <div className='absolute top-[0px] w-[100vw] z-[100]'>
            {header}
          </div>
        }
        {children}
      </div>
    </div>
  );
};

export default Layout;
