import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Pagination,
  StyledEngineProvider,
} from '@mui/material';
import { useRequest } from 'ahooks';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAccountList, toggleAccountStatus } from '../../apis';
import AppTopNav from '../../components/app-top-nav';
import './index.scss';

const PageSize = 10;

const StatusDesc: any = {
  0: '已停用',
  1: '已使用',
};

function User({ store }: any) {
  const [page, setPage] = useState(1);
  const [showToggleStatusDialog, setShowToggleStatusDialog] = useState(false);
  const accountRef = useRef<any>();
  const [updating, setUpdating] = useState(false);
  const { data, run } = useRequest(getAccountList, {
    defaultParams: [
      {
        page: 1,
        size: PageSize,
      },
    ],
  });
  const load = (page: number) =>
    run({
      page,
      size: PageSize,
    });

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    load(value);
  };

  // 确认删除文件
  const onConfirmToggleStatus = async () => {
    setUpdating(true);
    if (!accountRef.current) {
      return;
    }
    const { account_id, status } = accountRef.current;
    try {
      const { code, msg } = await toggleAccountStatus({
        accountID: account_id,
        enable: !status,
      });
      setShowToggleStatusDialog(false);
      if (code === 0) {
        load(page);
        store.openAlert(!status ? '已恢复用户授权' : '已停用用户授权')
      }
      setUpdating(false);
    } catch (e) {
      setUpdating(false);
    }
  };
  const navigateTo = useNavigate();
  const gridTemplateColumns = '2fr 2fr 2fr 2fr 3fr 3fr 2fr 2fr 2fr';

  return (
    <div className="wrapper-app-user">
      <AppTopNav
        onBackClick={() => {
          navigateTo(-1);
        }}
      />
      <div
        className="table-header mt-8 grid"
        style={{
          gridTemplateColumns,
        }}
      >
        <div className="table-cell">用户名</div>
        <div className="table-cell">状态</div>
        <div className="table-cell">用户ID</div>
        <div className="table-cell">用户来源</div>
        <div className="table-cell">激活时间</div>
        <div className="table-cell">充值金额（元）</div>
        <div className="table-cell">余额（元）</div>
        <div className="table-cell">操作</div>
      </div>
      {data?.items?.map((item: any) => (
        <div
          className="table-row grid"
          style={{
            gridTemplateColumns,
          }}
        >
          <div className="table-cell">{item.nickname}</div>
          <div className="table-cell">{StatusDesc[item.status] || ''}</div>
          <div className="table-cell">{item.account_id}</div>
          <div className="table-cell">{item.source}</div>
          <div className="table-cell">{item.created_at}</div>
          <div className="table-cell">{item.recharge_total}</div>
          <div className="table-cell">{item.fund_left}</div>
          <div className="table-cell">
            <div
              className="option-item"
              onClick={() => {
                setShowToggleStatusDialog(true);
                accountRef.current = item;
              }}
            >
              {item.status === 1 ? '停用授权' : '恢复授权'}
            </div>
          </div>
        </div>
      ))}
      <div className="table-footer">
        <Pagination
          classes={{
            text: 'wrapper-pagination',
          }}
          count={Math.ceil(data?.total / PageSize || 0)}
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
      </div>
      <Dialog classes={{ paper: 'confirm-dialog' }} open={showToggleStatusDialog}>
        <DialogContent>
          <DialogContentText className="confirm-dialog-text whitespace-pre-wrap">
            确认{accountRef.current?.status === 0 ? '恢复' : '停用'}{' '}
            <span
              style={{
                textDecoration: 'underline',
                margin: '0 2px',
              }}
            >
              {accountRef.current?.nickname}
            </span>
            ？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            classes={{
              outlined: 'confirm-dialog-button',
            }}
            onClick={() => setShowToggleStatusDialog(false)}
            disabled={updating}
          >
            取消
          </Button>
          <Button
            variant="contained"
            onClick={onConfirmToggleStatus}
            startIcon={updating && <CircularProgress size={20} color="inherit" />}
            disabled={updating}
          >
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function UserContainer(props: any) {
  return (
    <StyledEngineProvider injectFirst>
      <User {...props} />
    </StyledEngineProvider>
  );
}
