
import { Avatar } from '@mui/material';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import IconShiflow from '../../assets/icons/icon-shiflow.svg';
import PublishStatusTag from '../publish-status-tag';
import RetButton from '../ret-button';
import './index.scss';

const HeadBar: React.FC<{
  className?: string;
  onBackClick?: () => void;
  appInfo: IAppInfo;
  showTag?: boolean;
  rightButton?: ReactNode
}> = ({ appInfo: { status, introduction, icon, name }, onBackClick, className, showTag = true, rightButton }) => {
  const navigate = useNavigate();
  return (
    <div className={classNames('app-head-bar flex w-full flex-shrink-0 items-center bg-[#1F2028] pl-[20px] pr-[20px]', className)}>
      <RetButton
        className="mr-[25px] flex-shrink-0"
        onClick={() => {
          if (onBackClick) {
            onBackClick();
            return;
          }
          navigate(-1);
        }}
      />
      <Avatar
        className="mr-[12px]"
        src={icon || IconShiflow}
        sx={{
          width: 45,
          height: 45,
        }}
      />
      <div className="wrapper-app-name flex flex-col justify-center">
        <div className="flex items-center">
          <span className="app-name text-[20px] font-bold text-white">{name}</span>
          {showTag && <PublishStatusTag className="ml-[10px]" published={status === 'published'} />}
        </div>
        {introduction && <div className="text-[15px] text-white line-clamp-2">{introduction}</div>}
      </div>
      <div className="right-button">
        {rightButton}
      </div>
    </div>
  );
};

export default HeadBar;
