import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, MenuItem, OutlinedInput, Select } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createApplication, getAppSwitchs, getApplication, removeChats, removeFile, uploadAppIcon, uploadFile } from '../../apis';
import IconShiflow from '../../assets/icons/icon-shiflow.svg';
import ChatBase from '../../components/chat-base';
import TopNav from '../../components/top-nav';
import { useQrcodeModal } from '../../hooks/qrcode-modal';
import { MODEL, MODEL_PRICE } from '../../lib/constants';
import './index.scss';

interface IFile {
  id?: string
  key?: string
  title?: string
  isUploading?: boolean
}

enum SWITCH_STATUS {
  OPEN = 'open',
  CLOSE = 'close',
}

function Gpts({ store }: any) {
  const { id = '' } = useParams();
  const [appId, setAppId] = useState('')
  const [app, setApp] = useState<Record<string, any>>({
    name: '',
    icon: '',
    system_prompt: '',
    chat_model: MODEL.GPT3,
    image_model: '',
    voice_model: '',
  })
  const [switchs, setSwitchs] = useState<Record<string, string>>({
    switch_dalle: 'close',
    switch_elevenlabs: 'close',
    switch_mj: 'close',
    switch_search: 'close',
    switch_voice_ask: 'close',
  })
  const [fileList, setFileList] = useState<IFile[]>([])
  const [fileKey, setFileKey] = useState(0)
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false)
  const [removeFileIndex, setRemoveFileIndex] = useState(-1)
  const [removingFile, setRemovingFile] = useState(false)
  const [clearChatTag, setClearChatTag] = useState(false)
  const [QrcodeModal, setQrcodeModalVisible] = useQrcodeModal({
    title: '联系工作人员开通插件能力',
    type: 1,
    url: '/contact-qrcode.png',
  })

  useEffect(() => {
    initGpt(id)
    // 调试
    // getAllApplications()
    // if (id) {
    //   updateApplication(id, {
    //     subscribe_content: '北京欢迎你',
    //     preset_questions: ['你好', '你是谁'],
    //     context_length: 1000
    //   })
    // }
    // getFunds(1, 20)
    // if (false) {
    //   getWindows(id, 1, 20)
    //   createWindow(id)

    // }
  }, [])

  const initGpt = async (appId: string) => {
    if (appId) {
      initSwitch(appId)
      const data = await getApplication(appId)
      setApp(data)
      setAppId(data.id ?? '')
      setFileList(data.files?.map((file: any) => ({ id: file.id, key: file.file_id, title: file.title })) || [])
      console.log('获取应用信息:', data)
    } else {
      const data = await createApplication({
        chat_model: MODEL.GPT3
      })
      initSwitch(data.id)
      setApp(data)
      setAppId(data.id ?? '')
      console.log('创建应用信息:', data)
    }
  }

  const initSwitch = async (appId: string) => {
    if (!appId) return
    const data = await getAppSwitchs(appId)
    setSwitchs(data)
  }

  // 触发清空对话记录操作
  const onClearChat = async () => {
    const isSuccess = await removeChats(app.window_id)
    if (isSuccess) setClearChatTag(state => !state)
  }

  // AI图标
  const onIconChange = async (e: any) => {
    if (!appId) {
      return;
    }
    const icon = await uploadAppIcon(appId, e?.target?.files?.[0]);
    console.log('icon', icon)
    setApp((app) => {
      return { ...app, icon };
    });
  };

  // 上传文件
  const onUploadFile = async (e: any) => {
    if (appId) {
      const file = e?.target?.files?.[0]
      const key = `file-${fileKey}`
      const curFile = {
        id: '',
        key,
        title: file?.name ?? '文件上传中',
        isUploading: true,
      }
      setFileList(fileList => [curFile, ...fileList])
      setFileKey(fileKey => fileKey + 1)
      const { id, file_id, title } = await uploadFile(appId, file)
      if (id) {
        setFileList(fileList => {
          const newFileList = fileList.map(file => {
            if (file.key === key) {
              file.id = id
              if (file_id) file.key = file_id
              if (title) file.title = title
              file.isUploading = false
            }
            return file
          })

          setApp(app => {
            return { ...app, file_ids: newFileList?.map?.(f => f?.key)?.join(',') }
          })

          return newFileList
        })
      }
    }
  }

  // 删除文件
  const onRemoveFile = async (index: number) => {
    setRemoveFileIndex(index)
    setRemoveConfirmOpen(true)
  }

  // 确认删除文件
  const onConfirmRemoveFile = async () => {
    const id = fileList[removeFileIndex]?.id
    if (id) {
      setRemovingFile(true)
      const success = await removeFile(id)
      setRemoveConfirmOpen(false)
      setRemovingFile(false)
      if (success) {
        setFileList(fileList => {
          fileList.splice(removeFileIndex, 1)
          setRemoveFileIndex(-1)
          setApp(app => {
            return { ...app, file_ids: fileList?.map?.(f => f?.key)?.join(',') }
          })
          return fileList
        })
      }
    }
  }

  // 计算字数限制 英文1 中文2
  const calculateLength = (str: string) => {
    let length = 0;
    for (let i = 0; i < str.length; i++) {
      length += str.charCodeAt(i) > 127 ? 2 : 1;
    }
    return length;
  }

  // AI名称
  const onName = (e: any) => {
    console.log('onName', calculateLength(e?.target?.value))
    if (calculateLength(e?.target?.value) > 24) return
    setApp(app => {
      return { ...app, name: e?.target?.value }
    })
  }

  // 语言模型
  const onModelChat = (e: any) => {
    setApp(app => {
      return { ...app, chat_model: e?.target?.value }
    })
  }

  // 提示指令
  const onSystemPrompt = (e: any) => {
    setApp(app => {
      return { ...app, system_prompt: e?.target?.value }
    })
  }

  // 图片模型
  const onModelImage = (mode: string, e: any) => {
    setApp(app => {
      return { ...app, image_model: e?.target?.checked ? mode : '' }
    })
  }

  // 声音识别模型
  const onModelVoiceAsk = (mode: string, e: any) => {
    setApp(app => {
      return { ...app, voice_ask_model: e?.target?.checked ? mode : '' }
    })
  }

  // 声音模型
  const onModelVoice = (mode: string, e: any) => {
    setApp(app => {
      return { ...app, voice_model: e?.target?.checked ? mode : '' }
    })
  }

  // 联系客服
  const onContactClick = () => {
    setQrcodeModalVisible(true)
  }

  return (
    <div className="wrapper-editor">
      <TopNav config={app} onClearchat={onClearChat} />
      <div className="config">
        <div className="wrapper-upload-logo">
          <div className="uploader cursor-pointer">
            <img className="icon-upload-logo " src={app.id ? app.icon || IconShiflow : ''} alt="" />
            <input name="icon" type="file" onChange={onIconChange} />
          </div>
          <label>上传AI图标</label>
        </div>
        <label className="label">AI名称</label>
        <OutlinedInput
          value={app.name}
          size="small"
          classes={{
            input: 'font-style',
          }}
          placeholder="给你的AI一个独特的名字"
          onInput={onName}
          fullWidth
        />
        <label className="label">选择模型</label>
        <Select
          value={app.chat_model}
          size="small"
          classes={{
            icon: 'icon-style',
            select: 'font-style',
          }}
          onChange={onModelChat}
          fullWidth
        >
          <MenuItem value={MODEL.GPT3}>识流 3.5</MenuItem>
          <MenuItem value={MODEL.GPT4}>识流 4</MenuItem>
          <MenuItem value={MODEL.WXYY}>文心一言 4</MenuItem>
          <MenuItem value={MODEL.WXYY_TURBO}>文心一言 bot-turbo</MenuItem>
          <MenuItem value={MODEL.WXYY_FREE}>文心一言 免费版</MenuItem>
          <MenuItem value={MODEL.MINIMAX5}>minimax 5.5</MenuItem>
          <MenuItem value={MODEL.MINIMAX6}>minimax 6</MenuItem>
          <MenuItem value={MODEL.DOUBAO_LITE}>豆包 lite</MenuItem>
          <MenuItem value={MODEL.DOUBAO_PRO}>豆包 pro</MenuItem>
          {switchs.switch_yujuai === SWITCH_STATUS.OPEN && <MenuItem value={MODEL.YUJUAI}>AI视商</MenuItem>}
        </Select>
        <div className="model-tips">{MODEL_PRICE[app.chat_model]}算力/1000tokens</div>
        <label className="label">提示指令</label>
        <OutlinedInput
          value={app.system_prompt}
          multiline
          rows={9}
          placeholder="请输入文本"
          classes={{
            input: 'font-style',
          }}
          onInput={onSystemPrompt}
          fullWidth
        />
        <label className="label">知识库</label>
        <p className="tips-upload">
          支持上传pdf、txt、docx文件格式，如果用户的提问内容在知识库中有覆盖，AI将会调用该知识库内容，回复用户
        </p>
        <div className="file-list">
          {
            fileList?.map((file, index) =>
              <div className={classNames('file-item', { uploading: file.isUploading })} key={file.key}>
                {
                  file.isUploading
                    ? <CircularProgress size={20} className="file-icon" />
                    : <DescriptionIcon className="file-icon" />
                }
                <div className="file-title">
                  {file.title}
                </div>
                {!file.isUploading && <HighlightOffOutlinedIcon className="button-remove" onClick={onRemoveFile.bind(null, index)} />}
              </div>
            )
          }
        </div>
        <Button
          variant="outlined"
          classes={{
            outlined: 'button-upload',
          }}
          startIcon={<CloudUploadIcon />}
        >
          上传文件
          <input
            type="file"
            accept=".pdf, .txt, .docx"
            onChange={onUploadFile}
            key={`file-${fileKey}`}
          />
        </Button>
        <Dialog classes={{ paper: 'confirm-dialog' }} open={removeConfirmOpen}>
          <DialogContent>
            <DialogContentText className="confirm-dialog-text">
              确认删除？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              classes={{
                outlined: 'confirm-dialog-button'
              }}
              onClick={() => setRemoveConfirmOpen(false)}
              disabled={removingFile}
            >取消</Button>
            <Button
              variant="contained"
              onClick={onConfirmRemoveFile}
              startIcon={removingFile && <CircularProgress size={20} color="inherit" />}
              disabled={removingFile}
            >确认删除</Button>
          </DialogActions>
        </Dialog>
        <label className="label">
          扩展能力 <span className="link" onClick={onContactClick}>联系识流开通插件</span>
        </label>
        <div className="capability-list">
          {/* <label className={classNames('capability-item', { disabled: switchs.switch_search !== SWITCH_STATUS.OPEN })}>
            <Checkbox
              classes={{ root: 'checkbox' }}
              disabled={switchs.switch_search !== SWITCH_STATUS.OPEN}
            />
            联网能力
          </label> */}
          <label className={classNames('capability-item', { disabled: switchs.switch_voice_ask !== SWITCH_STATUS.OPEN })}>
            <Checkbox
              checked={app.voice_ask_model === MODEL.VOICE_ASK}
              classes={{ root: 'checkbox' }}
              onChange={onModelVoiceAsk.bind(null, MODEL.VOICE_ASK)}
              disabled={switchs.switch_voice_ask !== SWITCH_STATUS.OPEN}
            />
            语音理解
            {app.voice_ask_model === MODEL.VOICE_ASK && <> [10算力/秒]</>}
          </label>
          <label className={classNames('capability-item', { disabled: switchs.switch_voice !== SWITCH_STATUS.OPEN })}>
            <Checkbox
              checked={app.voice_model === MODEL.VOICE}
              classes={{ root: 'checkbox' }}
              onChange={onModelVoice.bind(null, MODEL.VOICE)}
              disabled={switchs.switch_voice !== SWITCH_STATUS.OPEN}
            />
            语音回复
            {app.voice_model === MODEL.VOICE && <> [1500算力/1000字符]</>}
          </label>
          <label className={classNames('capability-item', { disabled: switchs.switch_elevenlabs !== SWITCH_STATUS.OPEN })}>
            <Checkbox
              checked={app.voice_model === MODEL.ELEVENLABS}
              classes={{ root: 'checkbox' }}
              onChange={onModelVoice.bind(null, MODEL.ELEVENLABS)}
              disabled={switchs.switch_elevenlabs !== SWITCH_STATUS.OPEN}
            />
            人声克隆回复
            {app.voice_model === MODEL.ELEVENLABS && <> [2000算力/1000字符]</>}
          </label>
          <label className={classNames('capability-item', { disabled: switchs.switch_dalle !== SWITCH_STATUS.OPEN })}>
            <Checkbox
              checked={app.image_model === MODEL.DALLE}
              classes={{ root: 'checkbox' }}
              onChange={onModelImage.bind(null, MODEL.DALLE)}
              disabled={switchs.switch_dalle !== SWITCH_STATUS.OPEN}
            />
            DALL·E 3 文生图
            {app.image_model === MODEL.DALLE && <> [5000算力/图片]</>}
          </label>
          <label className={classNames('capability-item', { disabled: switchs.switch_midjourney !== SWITCH_STATUS.OPEN })}>
            <Checkbox
              checked={app.image_model === MODEL.MIDJOURNEY}
              classes={{ root: 'checkbox' }}
              onChange={onModelImage.bind(null, MODEL.MIDJOURNEY)}
              disabled={switchs.switch_midjourney !== SWITCH_STATUS.OPEN}
            />
            Midjourney 文生图
            {app.image_model === MODEL.MIDJOURNEY && <> [15000算力/图片]</>}
          </label>
          <label className={classNames('capability-item', { disabled: switchs.switch_sd3 !== SWITCH_STATUS.OPEN })}>
            <Checkbox
              checked={app.image_model === MODEL.SD3}
              classes={{ root: 'checkbox' }}
              onChange={onModelImage.bind(null, MODEL.SD3)}
              disabled={switchs.switch_sd3 !== SWITCH_STATUS.OPEN}
            />
            Stable Diffusion 3 文生图
            {app.image_model === MODEL.SD3 && <> [5000算力/图片]</>}
          </label>
        </div>
      </div>
      <ChatBase
        store={store}
        title={'对话调试'}
        config={app}
        clearChatTag={clearChatTag}
        isEdit={true}
      />
      <QrcodeModal />
    </div>
  );
}

export default Gpts;
