import { Button, Dialog, DialogContent, DialogContentText, OutlinedInput } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from "mobx-react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSelf, login, loginPartner } from '../../apis';
import Store from '../../stores/index';
import { WeixinQRCodeModal } from '../weixin-bind-modal';
import './index.scss';

function _LoginModal({ onCallback = () => { }, onChangeLogin = () => { } }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [logining, setLogining] = useState(false);
  const [error, setError] = useState(false);

  const partnerHostnames = [
    'shishang.shiflow.com',
    'ai.314live.net',
    // 'localhost',
  ];
  const hostname = window.location.hostname;
  const isPartner = partnerHostnames.includes(hostname);

  const onUsername = (e: any) => {
    setUsername(e?.target?.value);
    if (error) setError(false);
  };

  const onPassword = (e: any) => {
    setPassword(e?.target?.value);
    if (error) setError(false);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onLogin();
    }
  };

  const onLogin = async () => {
    setLogining(true);
    setError(false);
    const hostname = window.location.hostname;

    const loginFn = isPartner ? loginPartner : login;

    const { access_token: token } = await loginFn(username, password);
    if (token) {
      Store.setToken(token, false);
      const selfInfo = await getSelf();
      const { account_type: accountType, is_bind_weixin, switch_code: switchCode } = selfInfo;
      if (!is_bind_weixin) {
        onChangeLogin();
        return;
      }
      Store.setToken(token, true);
      Store.setSelfInfo(selfInfo);
      Store.setShowLogin(false);
      Store.setAccountType(accountType);
      Store.setSwitchCode(switchCode);
      Store.openAlert('登录成功');
      onCallback?.();
      if (Store.callbackUrl) {
        navigate(Store.callbackUrl);
        Store.setCallbackUrl('');
      }
    } else {
      setError(true);
    }
    setLogining(false);
  };

  const onClose = () => {
    Store.setShowLogin(false);
  };

  return (
    <Dialog classes={{ paper: 'login-modal' }} open={Store.showLogin}>
      <DialogContent>
        <DialogContentText className="title">{isPartner ? <>登录</> : <>识流登录</>}</DialogContentText>
        <OutlinedInput
          value={username}
          size="small"
          classes={{
            input: 'font-style',
            root: 'input',
            notchedOutline: 'notchedOutline',
          }}
          placeholder="帐号"
          onInput={onUsername}
          fullWidth
        />
        <OutlinedInput
          value={password}
          size="small"
          classes={{
            input: 'font-style',
            root: 'input',
            notchedOutline: 'notchedOutline',
          }}
          placeholder="密码"
          type="password"
          onInput={onPassword}
          onKeyDown={onKeyDown}
          fullWidth
        />
        <div className="wrapper-button">
          <Button
            variant="outlined"
            classes={{
              outlined: 'confirm-dialog-button',
            }}
            onClick={onClose}
          >
            取消
          </Button>
          <Button
            variant="contained"
            classes={{
              disabled: 'login-disabled',
            }}
            onClick={onLogin}
            disabled={!username || !password || logining}
          >
            {logining ? <CircularProgress size={20} color="inherit" /> : '登录'}
          </Button>
        </div>
        {/* {!isPartner && (
          <div className="wrapper-link">
            <a
              href="https://shiflowai.feishu.cn/share/base/form/shrcn9swU4ISQNWy1IPYIaQtx0e"
              target="_blank"
              rel="noreferrer"
            >
              加入识流专业版内测
            </a>
          </div>
        )} */}
        {error && <div className="wrapper-error">登录失败，帐号或密码错误</div>}
      </DialogContent>
    </Dialog>
  );
}
const LoginModal = observer(_LoginModal);

const WeixinModal: React.FC<{ onChangeLogin: () => void }> = observer(({ onChangeLogin }) => {
  if (!Store.showLogin) {
    return <></>;
  }
  return (
    <WeixinQRCodeModal
      isLogin={!Store.token}
      open={Store.showLogin}
      onChangeLogin={onChangeLogin}
      onClose={() => {
        Store.setShowLogin(false);
      }}
    />
  );
});

const Modal = (props: any) => {
  const [isWeixinLogin, setWeixinLogin] = useState(true);
  useEffect(() => {
    console.info('wtf Store.showLogin', Store.showLogin);
    if (!Store.showLogin) {
      return;
    }
    setWeixinLogin(true);
  }, [Store.showLogin]);
  return isWeixinLogin ? (
    <WeixinModal onChangeLogin={() => setWeixinLogin(false)} />
  ) : (
    <LoginModal {...props} onChangeLogin={() => setWeixinLogin(true)} />
  );
}

export default observer(Modal);
