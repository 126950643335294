import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Drawer, Grid, Button as MaterialButton, OutlinedInput } from '@mui/material';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAppFund, getAppSwitchs, getApplication, getCorpAppFund, getWeixinmpConfigs, removeWeixinmpConfigs, updateWeixinmpConfigs } from '../../../../../apis';
import AccountAnalytics from '../../../../../components/account-analytics';
import AmountCard from '../../../../../components/amount-card';
import Button from '../../../../../components/button';
import ConfirmModal from '../../../../../components/confirm-modal';
import HeadBar from '../../../../../components/head-bar';
import { useQrcodeModal } from '../../../../../hooks/qrcode-modal';
import { isDev } from '../../../../../lib/constants';
import Store from '../../../../../stores/index';
import ContactPopup from '../contact';
import styles from './index.module.scss';
import './index.scss';

const panelTitleClass = classNames('mb-[13px] text-[20px] font-bold text-white');
const buttonCommonProps = {
  className: styles.actionButton,
  width: 188,
  height: 63,
  size: 'large',
} as const;

const MP_URL = 'https://mp.weixin.qq.com'
const CONFIG_INTRO_URL = 'https://shiflowai.feishu.cn/wiki/QoqmwWz2VioV6PkKc1UcDTEjnLf'
const enum WEIXINMP_CONFIG_STATUS {
  DONE = 'done',
  NONE = 'none',
}

const ActionPanel: React.FC<{
  data: IAppInfo;
}> = ({ data }) => {
  const navigate = useNavigate();
  return (
    <section>
      <header className={panelTitleClass}>操作</header>
      <Grid container spacing="20px" justifyContent="flex-start">
        <Grid item>
          <Link to={`/gpts/editor/${data.id}`}>
            <Button {...buttonCommonProps}>
              <i className={classNames(styles.iconEdit, 'mr-[16px]')} />
              AI对话调试
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Link to={`/gpts/memory/${data.id}`}>
            <Button {...buttonCommonProps}>
              <i className={classNames(styles.iconBook, 'mr-[16px]')} />
              知识库
            </Button>
          </Link>
        </Grid>
        {/*<Grid item>*/}
        {/*  <Button*/}
        {/*    {...buttonCommonProps}*/}
        {/*    onClick={() => {*/}
        {/*      navigate(`/apps/${data.id}/account`);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <i className={classNames(styles.iconBook, 'mr-[px]')} />*/}
        {/*    用户列表*/}
        {/*  </Button>*/}
        {/*</Grid>*/}
      </Grid>
    </section>
  );
};

const DeployPanel: React.FC<{
  className?: string;
  appid: string;
  onContactClick: () => void;
  onConfigClick: () => void;
}> = ({ className, onContactClick, onConfigClick, appid }) => {
  const [switchs, setSwitchs] = useState<Record<string, string>>({
    switch_weixinmp: 'close',
  })
  const chatUrl = isDev ? `http://gpts-test.shiflow.com/chat/${appid}` : `http://gpts.shiflow.com/chat/${appid}`
  const [QrcodeModal, setQrcodeModalVisible] = useQrcodeModal({
    title: '扫码访问',
    url: chatUrl,
  })

  useEffect(() => {
    initSwitch()
  }, [])

  const initSwitch = async () => {
    if (!appid) return
    const data = await getAppSwitchs(appid)
    setSwitchs(data)
  }

  const onCopyChatUrl = () => {
    copy(chatUrl)
    Store.openAlert('复制成功')
  }

  const onChatQrcode = () => {
    setQrcodeModalVisible(true)
  }

  return (
    <section className={className}>
      <header className={panelTitleClass}>应用网页版</header>
      <div className="flex items-center mb-[15px]">
        <div className="text-[16px] text-[#fff] bg-[#3D404A] h-[40px] rounded flex items-center pl-[15px] pr-[15px] mr-[15px]">
          {chatUrl}
        </div>
        <Button className={classNames(styles.buttonDeploy, 'mr-[15px]')} onClick={onCopyChatUrl}>复制地址</Button>
        <Button className={styles.buttonDeploy} onClick={onChatQrcode}>扫码访问</Button>
      </div>
      <div className="text-[15px] text-[#a0a0a0] mb-[30px]">
        您可以把链接分享给您的用户，把链接绑定到微信公众号菜单
      </div>
      <header className={panelTitleClass}>激活微信私域能力（已激活）</header>
      <div className="text-[15px] text-[#a0a0a0]">
        识流AI支持您把创建的AI绑定到微信私域生态，帮助您实现变现。支持绑定公众号服务号、小程序、网页应用等方案。详情联系
        <span className="text-[#3e8ce2] underline ml-[5px] cursor-pointer" onClick={onContactClick}>识流工作人员</span>。
      </div>
      <div className={styles.deployList}>
        <div className={styles.deployItem}>
          <div className={styles.wrapperLabel}>
            <i className={styles.iconOfficialAccount} />
            绑定公众号
          </div>
          {
            switchs.switch_weixinmp === 'open'
              ? <Button className={styles.buttonDeploy} onClick={onConfigClick}>配置</Button>
              : <Button className={styles.buttonDeploy} onClick={onContactClick}>联系识流</Button>
          }
        </div>
        <div className={styles.deployItem}>
          <div className={styles.wrapperLabel}>
            <i className={styles.iconMp} />
            定制小程序
          </div>
          <Button
            className={styles.buttonDeploy}
            onClick={onContactClick}
          >联系识流</Button>
        </div>
      </div>
      <QrcodeModal />
    </section>
  );
};

const ProfileContent = () => {
  const { appid = '' } = useParams();
  const navigate = useNavigate();
  const [app, setApp] = useState<IAppInfo>({} as IAppInfo)
  const [fund, setFund] = useState<Record<string, any>>({})
  const [showContact, setShowContact] = useState(false);
  const [drawerConfigOpen, setDrawerConfigOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorKey, setErrorKey] = useState('');
  const [url, setUrl] = useState('');
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false)
  const [pending, setPending] = useState(false)
  // const [weixinConfig, setWeixinConfig] = useState<Record<string, string>>({
  //   weixinmp_origin_id: 'gh_395e7eb8bc6d',
  //   weixinmp_app_id: 'wxea895986e49d2c01',
  //   weixinmp_app_secret: '8c019bd99fe4d703a2f1ff571b2fc736',
  //   weixinmp_token: 'shiflow2023',
  //   weixinmp_encoding_aes_key: '3LzrzCbcmFd3t2JfegKG3gVqtRSqMcetTqTN0GzDgFf',
  // })
  const [weixinConfig, setWeixinConfig] = useState<Record<string, string>>({
    weixinmp_origin_id: '',
    weixinmp_app_id: '',
    weixinmp_app_secret: '',
    weixinmp_token: '',
    weixinmp_encoding_aes_key: '',
    weixinmp_config_status: '',
    weixinmp_config_url: '',
  })


  const configItems = useRef([{
    label: '原始ID（Original ID）*',
    placeholder: '请输入原始ID',
    key: 'weixinmp_origin_id'
  }, {
    label: '开发者ID（AppID）*',
    placeholder: '请输入开发者ID',
    key: 'weixinmp_app_id'
  }, {
    label: '开发者密码（AppSecret）*',
    placeholder: '请输入开发者密码',
    key: 'weixinmp_app_secret'
  }, {
    label: '令牌（Token）*',
    placeholder: '请输入令牌',
    key: 'weixinmp_token'
  }, {
    label: '消息加密密钥（EncodingAESKey）*',
    placeholder: '请输入消息加密密钥',
    key: 'weixinmp_encoding_aes_key'
  }])

  useEffect(() => {
    if (Store.selfLoaded) {
      initApp()
      initFund()
    }
  }, [Store.selfLoaded])

  const initApp = async () => {
    const app = await getApplication(appid)
    setApp(app)
  }

  const initFund = async () => {
    const getFund = Store.accountType === 'partner' ? getCorpAppFund : getAppFund
    const fund = await getFund(appid)
    setFund(fund)
  }

  const initConfigs = async () => {
    const data = await getWeixinmpConfigs(appid)
    setWeixinConfig(data)
  }

  const onOpenConfig = async () => {
    await initConfigs()
    setDrawerConfigOpen(true)
  }

  const onUpdateConfig = async () => {
    const validators = [
      'weixinmp_origin_id',
      'weixinmp_app_id',
      'weixinmp_app_secret',
      'weixinmp_token',
      'weixinmp_encoding_aes_key',
    ]

    for (const key in weixinConfig) {
      if (!weixinConfig[key] && validators.includes(key)) {
        setErrorMsg('')
        setErrorKey(key)
        return
      }
    }

    setErrorKey('')
    setErrorMsg('')
    const data = await updateWeixinmpConfigs(appid, {
      weixinmp_origin_id: weixinConfig.weixinmp_origin_id,
      weixinmp_app_id: weixinConfig.weixinmp_app_id,
      weixinmp_app_secret: weixinConfig.weixinmp_app_secret,
      weixinmp_token: weixinConfig.weixinmp_token,
      weixinmp_encoding_aes_key: weixinConfig.weixinmp_encoding_aes_key,
    })
    if (data.body) {
      setErrorMsg('')
      setUrl(data.body)
    } else {
      setErrorMsg(data.msg)
    }
  }

  // 询问是否解绑
  const onOpenRemoveConfig = async () => {
    setRemoveConfirmOpen(true)
  }

  // 取消解绑
  const onCancelRemove = () => {
    setRemoveConfirmOpen(false)
  }

  // 确认解绑
  const onConfirmRemove = async () => {
    setPending(true)
    const success = await removeWeixinmpConfigs(appid)
    if (success) {
      Store.openAlert('公众号解绑成功')
      initConfigs()
    }
    setRemoveConfirmOpen(false)
    setPending(false)
  }

  const onCloseUpdateConfig = () => {
    setDrawerConfigOpen(false)
    setErrorKey('')
    setErrorMsg('')
    setUrl('')
    setWeixinConfig({
      weixinmp_origin_id: '',
      weixinmp_app_id: '',
      weixinmp_app_secret: '',
      weixinmp_token: '',
      weixinmp_encoding_aes_key: '',
    })
  }

  const onInputConfig = (key: string, e: any) => {
    setWeixinConfig((config: any) => {
      return {
        ...config,
        [key]: e?.target?.value
      }
    })
  }

  const onCopyUrl = () => {
    copy(url)
    Store.openAlert('地址复制成功')
  }

  const getConfigItem = (item: any) => {
    return <>
      <label className="label">{item.label}</label>
      {
        weixinConfig.weixinmp_config_status === WEIXINMP_CONFIG_STATUS.DONE
          ? <div className="value-text">{weixinConfig[item.key]}</div>
          : <>
            <OutlinedInput
              value={weixinConfig[item.key]}
              size="small"
              classes={{
                root: 'input-root',
                notchedOutline: 'input-outline',
                input: 'input-font',
              }}
              placeholder={item.placeholder}
              onInput={onInputConfig.bind(null, item.key)}
            />
            <div className="wrapper-error">{errorKey === item.key && item.placeholder}</div>
          </>
      }
    </>
  }

  return (
    <section className="flex h-full flex-col">
      <ContactPopup
        text="联系工作人员开通识流专业版账号，开通微信公众号、小程序能力"
        visible={showContact}
        onCloseClick={() => setShowContact(false)}
      />
      <HeadBar
        appInfo={app}
        onBackClick={() => {
          navigate('/apps', { replace: true });
        }}
      />
      <div className="flex grow overflow-y-auto">
        <aside className="bg-[#1b1c23] px-[50px] pt-[70px]">
          <AmountCard
            amount={Store.accountType === 'partner' ? fund?.total_cost : fund?.income}
            amountText={Store.accountType === 'partner' ? '用户消耗金额（元）' : 'AI收益（元）'}
          />
          <AccountAnalytics
            className="mt-[75px]"
            list={Store.accountType === 'partner' ? [
              {
                label: '激活用户数（人）',
                value: fund?.register_count,
              },
              // {
              //   label: '用户消耗金额（元）',
              //   value: fund?.total_cost,
              // },
              {
                label: '算力成本（元）',
                value: fund?.sf_cost,
              },
            ] : [
              {
                label: '激活用户数（人）',
                value: fund?.register_count,
              },
              {
                label: '用户消耗金额（元）',
                value: fund?.total_cost,
              },
              {
                label: '算力成本（元）',
                value: fund?.sf_cost,
              },
            ]}
          />
        </aside>
        <div className="px-[52px] pt-[67px] w-full">
          <ActionPanel data={app} />
          <DeployPanel
            appid={appid}
            className="pt-[63px]"
            onContactClick={() => {
              setShowContact(true);
            }}
            onConfigClick={onOpenConfig}
          />
          <Drawer
            open={drawerConfigOpen}
            anchor={'right'}
            classes={{
              paper: 'wrapper-bind-drawer'
            }}
            onClose={onCloseUpdateConfig}
          >
            <div className="title">配置微信公众号（服务号）</div>
            <p className="desc">
              前往<a href={MP_URL} target="_blank" rel="noreferrer">微信公众平台</a>，按照<a href={CONFIG_INTRO_URL} target="_blank" rel="noreferrer">识流AI公众号绑定配置流程</a>并发布AI，公众号即可利用AI回复用户信息，助力微信运营无间断。
            </p>
            {
              !url
                ? <>
                  <div className="subtitle"><span className="title-number">1</span>填写微信公众号配置信息</div>
                  <p className="desc">
                    {'在操作本步骤之前，请确认已按照'}<a href={CONFIG_INTRO_URL} target="_blank" rel="noreferrer">配置流程</a>{'在微信公众平台配置IP白名单。完成后，将下列微信公众平台内容粘贴到识流：设置与开发 > 公众号设置页面的“原始ID”；设置与开发 > 基本配置页面的“开发者ID”、“开发者密码”、“令牌”和“消息加密密钥”。'}
                  </p>
                  {configItems.current.map((item: any) => getConfigItem(item))}
                  {errorMsg && <div className="wrapper-error">{errorMsg}</div>}
                  {
                    weixinConfig.weixinmp_config_status === WEIXINMP_CONFIG_STATUS.DONE
                      ? <>
                        <label className="label">微信公众平台配置URL</label>
                        <div className="value-text">{weixinConfig.weixinmp_config_url}</div>
                        <MaterialButton
                          size="large"
                          variant="contained"
                          classes={{
                            root: 'button-next',
                            disabled: 'publish-disabled',
                          }}
                          fullWidth
                          onClick={onOpenRemoveConfig}
                        >
                          解绑微信公众号（服务号）
                        </MaterialButton>
                      </>
                      : <MaterialButton
                        size="large"
                        variant="contained"
                        classes={{
                          root: 'button-next',
                          disabled: 'publish-disabled',
                        }}
                        fullWidth
                        onClick={onUpdateConfig}
                      >
                        下一步
                      </MaterialButton>
                  }
                </>
                : <>
                  <div className="subtitle"><span className="title-number">2</span>在微信公众平台配置URL</div>
                  <p className="desc">
                    {'请先复制下列链接至剪贴板，并点击配置弹窗底部“保存”后，再前往微信公众平台“设置与开发” > “基本配置” > “服务器配置”，将下列链接粘贴到服务器地址（URL）。'}
                  </p>
                  <p className="desc url-wrapper" onClick={onCopyUrl}>
                    {url}<ContentCopyIcon className="icon-copy" />
                  </p>
                  <MaterialButton
                    size="large"
                    variant="contained"
                    classes={{
                      root: 'button-next',
                      disabled: 'publish-disabled',
                    }}
                    fullWidth
                    onClick={() => {
                      onCopyUrl()
                      onCloseUpdateConfig()
                    }}
                  >
                    完成
                  </MaterialButton>
                </>
            }
          </Drawer>
          <ConfirmModal
            open={removeConfirmOpen}
            title={'确认解绑公众号?'}
            onCancel={onCancelRemove}
            onConfim={onConfirmRemove}
            confirmText={'确认解绑'}
            pending={pending}
          />
        </div>
      </div>
    </section>
  );
};

export default observer(ProfileContent);
