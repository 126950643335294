import { Button, StyledEngineProvider } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCorpSelfFund, getSelfApplications, getSelfFund } from '../../../apis';
import AccountAnalytics from '../../../components/account-analytics';
import AppCard from '../../../components/app-card';
import { useWeixinLoginModal } from '../../../components/weixin-bind-modal';
import { useDrawer } from '../../../hooks/drawer/index';
import { useQrcodeModal } from '../../../hooks/qrcode-modal';
import { isDev } from '../../../lib/constants';
import { useContactPopup } from './components/contact';
import ProfileContent from './components/profile-content';
import './index.scss';

enum SWITCH_CODE_STATUS {
  OPEN = 'open',
  CLOSE = 'close',
}

export default function Applist({ store }: any) {
  return (
    <StyledEngineProvider injectFirst>
      <div className="wrapper-profile h-screen min-w-full">
        <ProfileContent />
      </div>
    </StyledEngineProvider>
  );
}

export function AppList({ store }: any) {
  const navigate = useNavigate()
  const [apps, setApps] = useState<any[]>([])
  const [fund, setFund] = useState<Record<string, any>>({})
  const [analyticsList, setAnalyticsList] = useState<any[]>([])
  const [_contactPopup, { show: showContactPopup }] = useContactPopup({ text: '联系工作人员开通识流专业版账号，完成算力充值' })
  const [Drawer, setDrawerVisible] = useDrawer({
    title: '算力充值',
  })
  const [QrcodeModal, setQrcodeModalVisible] = useQrcodeModal({
    title: '微信扫码去充值',
    url: isDev ? 'http://gpts-test.shiflow.com/recharge?source=shiflowtest' : 'http://gpts.shiflow.com/recharge?source=shiflow',
  })
  const [WeixinLoginModal, setWeixinLoginModalVisible] = useWeixinLoginModal()
  const [curAmountIndex, setCurAmountIndex] = useState(0)
  const amountConfig = useRef([
    { amount: 69, totalTokens: 690000 },
    { amount: 199, totalTokens: 1990000 },
    { amount: 299, totalTokens: 2990000 },
  ])

  useEffect(() => {
    if (store.selfLoaded) {
      initApps()
      initFund()
    }
  }, [store.selfLoaded])

  const initApps = async () => {
    const { list: apps = [] } = await getSelfApplications()
    setApps(apps)
  }

  const initFund = async () => {
    const getFund = store.accountType === 'partner' ? getCorpSelfFund : getSelfFund;
    const fund = await getFund()
    setFund(fund)
    setAnalyticsList(
      store.accountType === 'partner'
        ? [
          { label: '总激活用户数（人）', value: fund?.register_count },
          { label: '总收益（元）', value: fund?.income},
        ]
        : [
          { label: '本周收入（元）', value: fund?.income },
          { label: '本周对话次数', value: fund?.chat_count },
          { label: '本周对话人数', value: fund?.chat_uv },
        ]
    )
  }

  const onChooseAmount = (index: number) => {
    setCurAmountIndex(index)
  }

  const onRecharge = () => {
    console.log('onRecharge', amountConfig.current[curAmountIndex]);
    setDrawerVisible(false)
    setQrcodeModalVisible(true)
  }

  return (
    <>
      <WeixinLoginModal />
      {_contactPopup}
      <div className="flex">
        <aside className="flex flex-col bg-[#1b1c23] px-[20px] pt-[85px]">
          <div className="applist-analytics">
            <div className="applist-analytics-balance">
              <div className="applist-analytics-label">我的剩余算力</div>
              <div className="applist-analytics-amount">{fund?.sf_balance ?? 0}</div>
              <div className="mt-[12px] flex justify-end">
                <Button
                  style={{
                    backgroundColor: '#3e8ce2',
                    width: 106,
                    boxShadow: 'none',
                    height: 40,
                    borderRadius: 5,
                    border: 'solid 1px #fff',
                    fontSize: 15,
                  }}
                  variant="contained"
                  onClick={() => {
                    // showContactPopup();
                    // setDrawerVisible(true)
                    if (!store.selfInfo?.is_bind_weixin) {
                      setWeixinLoginModalVisible(true);
                      return;
                    }
                    setQrcodeModalVisible(true)
                  }}
                >
                  <span
                    style={{
                      letterSpacing: 9,
                      textIndent: 9,
                    }}
                  >
                    充值
                  </span>
                </Button>
              </div>
            </div>
            <AccountAnalytics className="applist-account-analytics text-white" list={analyticsList} />
          </div>
          {
            store.switchCode === SWITCH_CODE_STATUS.OPEN && <div className="wrapper-entrances" style={{ marginTop: 27 }}>
              <Link to="/redeem">
                <Button className="applist-entrance-button">
                  <i className="applist-icon-redeem-code" />
                  <span className="applist-entrance-button-text text-white">充值邀请码</span>
                </Button>
              </Link>
              <Link to="/account-management">
                <Button className="applist-entrance-button">
                  <i className="applist-icon-user" />
                  <span className="applist-entrance-button-text text-white">用户列表</span>
                </Button>
              </Link>
            </div>
          }
          <Button
            onClick={() => {
              store.logout();
              navigate('/');
            }}
            variant="outlined"
            color="inherit"
            className="applist-logout-button mt-auto self-center"
          >
            退出登录
          </Button>
        </aside>
      </div>

      <div className="wrapper-apps">
        <Link className="self-end mr-[40px]" to="/gpts/editor">
          <Button className="button-create-ai float-right w-[110px] self-end" variant="contained">
            创建AI
          </Button>
        </Link>
        <div className="apps">
          {apps?.map((item: any, index: number) => (
            <AppCard key={index} data={item as IAppInfo} className="app-item" />
          ))}
        </div>
      </div>

      <Drawer>
        <div className="wrapper-amount-drawer">
          <div className="amount-desc">充值算力，可在所用的AI模型、应用中使用，根据使用量扣除算力</div>
          <div className="wrapper-amount">
            {
              amountConfig.current.map((config, index) =>
                <div
                  className={classNames('amount-item', { active: curAmountIndex === index })}
                  onClick={onChooseAmount.bind(null, index)}
                  key={index}
                >
                  <span>{config.amount}元</span>
                  <span>{config.totalTokens}算力</span>
                </div>
              )
            }
          </div>
          <Button
            size="large"
            variant="contained"
            className="button-amount"
            fullWidth
            onClick={onRecharge}
          >
            充值
          </Button>
        </div>
      </Drawer>
      <QrcodeModal />
    </>
  );
}
