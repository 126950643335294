import { Dialog } from '@mui/material';
import QRCode from 'qrcode.react';
import { ReactNode, useState } from "react";
import './index.scss';

interface IQrcodeModalHooks {
  title?: ReactNode,
  type?: UrlType,
  url: string,
}

const enum UrlType {
  linkUrl = 0,
  imageUrl = 1,
}

export const useQrcodeModal = ({ title, type = 0, url }: IQrcodeModalHooks) => {
  const [visible, setVisible] = useState(false);

  return [
    () => (
      <Dialog
        open={visible}
        classes={{
          paper: 'wrapper-qrcode-modal-hooks'
        }}
        onClose={() => setVisible(false)}
      >
        {title && <div className="qrcode-modal-title">{title}</div>}
        {
          type === UrlType.linkUrl
            ? <div className="wrapper-qrcode">
              <QRCode className="qrcode" value={url} size={210} />
            </div>
            : <img className="qrcode" src={url} alt="" />
        }
      </Dialog>
    ),
    setVisible
  ] as const;
};