import React from 'react';
import { Grid } from '@mui/material';
import styles from './index.module.scss';
import classNames from 'classnames';

const AccountAnalytics: React.FC<{
  list: {
    label: string;
    value: number | string;
  }[];
  className?: string;
}> = ({ list, className }) => {
  return (
    <Grid container className={classNames(styles.ctn, className)}>
      {list.map(({ label, value }, index) => {
        return (
          <Grid key={index} item xs={4}>
            <div className={styles.value}>{value ?? '-'}</div>
            <div className={classNames(styles.label, 'account-analytics-label')}>{label}</div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AccountAnalytics;
