import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Avatar, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import TextareaAutosize from 'react-textarea-autosize';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { getChats } from '../../apis';
import IconShiflow from '../../assets/icons/icon-shiflow.svg';
import { API_HOST, MODEL } from '../../lib/constants';
import AudioBubble from '../audio-bubble';
import './index.scss';

const enum msgType {
  TEXT = 'text',
  IMAGE = 'image',
  VOICE = 'voice',
  VOICE_STREAM = 'voice_stream',
}

const enum roleType {
  ME = 'me',
  GPT = 'gpt'
}

interface IMessage {
  id?: number,
  content?: string
  type?: string
  duration?: number
  role?: string
  costSeconds?: number
  tokens?: number
  totalTokens?: number
  isSplit?: boolean
  autoPlay?: boolean
  imageModel?: string
}

const HEARTBEAT_DURATION = 1000 * 30
const audioContext = new window.AudioContext();
let startObserver: any = null
let endObserver: any = null
let wsTimer: any = null

function ChatBase({
  store,
  title = '',
  config = {},
  clearChatTag = false,
  isEdit = false,
  onFirstAnswer,
}: any) {
  const [ws, setWs] = useState<WebSocket | null>(null)
  const [message, setMessage] = useState<string>('')
  const [uncompletedMessage, setUncompletedMessage] = useState<IMessage>({})
  const [messages, setMessages] = useState<IMessage[]>([])
  const [lastTime, setLastTime] = useState<number>(0)
  const [isShowScrollButton, setIsShowScrollButton] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [copyIndex, setCopyIndex] = useState(-1)
  const [lastGetChatId, setLastGetChatId] = useState(-1)
  const [reachTop, setReachTop] = useState(true)
  const messagesEndRef = useRef(null)
  const messagesStartRef = useRef(null)
  const isCompositingRef = useRef(false);

  config = {
    id: config.id,
    name: config.name || 'GPT',
    icon: config.icon || IconShiflow,
    system_prompt: config.system_prompt || '',
    chat_model: config.chat_model || '',
    image_model: config.image_model || '',
    voice_model: config.voice_model || '',
    voice_ask_model: config.voice_ask_model || '',
    window_id: config.window_id,
    file_ids: config.file_ids,
    introduction: config.introduction
  }

  // 到顶部触发加载对话记录
  useEffect(() => {
    if (reachTop && config.window_id) getChatHistory()
  }, [reachTop, config.window_id])

  // 触发清空对话记录
  useEffect(() => {
    setMessages([])
  }, [clearChatTag])

  // 触发应用已更新分割线
  useEffect(() => {
    if (isPending || !messages?.length || messages[messages.length - 1]?.isSplit) return
    setMessages(messages => {
      return [...messages, { isSplit: true }]
    })
  }, [
    config.system_prompt,
    config.chat_model,
    config.voice_ask_model,
    config.file_ids
  ])

  useEffect(() => {
    if (isPending || !messages?.length || (messages[messages.length - 1]?.isSplit && !config.image_model)) return
    setMessages(messages => {
      return config.image_model
        ? [
          ...messages,
          { isSplit: true },
          {
            content: '告诉我“画”什么，我就可以作画，例如“画一个银河系漫游指南”',
            type: msgType.TEXT,
            role: roleType.GPT,
          }
        ]
        : [
          ...messages,
          { isSplit: true },
        ]
    })
  }, [config.image_model])

  useEffect(() => {
    if (isPending || !messages?.length || (messages[messages.length - 1]?.isSplit && !config.voice_model)) return
    setMessages(messages => {
      return config.voice_model
        ? [
          ...messages,
          { isSplit: true },
          {
            content: '输入“语音回复”并输入问题可得到语音回复（绑定微信公众号后，用户可直接语音输入和得到语音回复）',
            type: msgType.TEXT,
            role: roleType.GPT,
          }
        ]
        : [
          ...messages,
          { isSplit: true },
        ]
    })
  }, [config.voice_model])

  // 初始化对话数据
  useEffect(() => {
    initSocket()

    return () => {
      ws?.close()
      clearHeartbeat()
    };
  }, [])

  // 监听触底
  useEffect(() => {
    if (messagesEndRef?.current && !endObserver) {
      endObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIsShowScrollButton(false)
        } else if (!uncompletedMessage.content) {
          setIsShowScrollButton(true)
        }
      }, {
        root: null,
        threshold: 1
      });

      endObserver.observe(messagesEndRef.current);
    }
  }, [messagesEndRef.current])

  // 监听触顶
  useEffect(() => {
    if (messagesStartRef?.current && !startObserver) {
      startObserver = new IntersectionObserver((entries) => {
        setReachTop(!!entries[0].isIntersecting)
      }, {
        root: null,
        threshold: 1
      });

      startObserver.observe(messagesStartRef.current);
    }
  }, [messagesStartRef.current])

  // 数据更新滚动到底部
  useEffect(() => {
    scrollToBottom()
  }, [messages])

  // 数据更新滚动到底部
  useEffect(() => {
    const now = Date.now()
    if (now - lastTime <= 1000) return
    setLastTime(now)
    scrollToBottom()
  }, [uncompletedMessage])

  let bufferQueue: any[] = []
  let sourceNode: any = null
  let isAudioPlaying = false

  const playNextBuffer = () => {
    if (bufferQueue.length === 0 || isAudioPlaying) {
      return;
    }
    // 获取队列中的下一个音频缓冲区
    const buffer = bufferQueue.shift();
    // 创建一个新的AudioBufferSourceNode
    sourceNode = audioContext.createBufferSource();
    sourceNode.buffer = buffer;
    sourceNode.connect(audioContext.destination);
    sourceNode.onended = () => {
      // 当前片段播放结束后，播放下一个片段
      isAudioPlaying = false
      playNextBuffer();
    };
    // 开始播放
    sourceNode.start();
    isAudioPlaying = true
    console.log('sourceNode.playbackRate', sourceNode?.playbackRate)
  }

  const addBuffer = (buffer: any) => {
    // 将新的音频缓冲区添加到队列
    bufferQueue.push(buffer);
    // 如果当前没有音频正在播放，立即播放这个音频
    playNextBuffer();
  }

  const decodeData = (data: any) => {
    const decodedData = atob(data?.content)
    const len = decodedData.length
    const audioData = new Uint8Array(len)

    for (let i = 0; i < len; i++) {
      audioData[i] = decodedData.charCodeAt(i);
    }

    audioContext.decodeAudioData(audioData.buffer, addBuffer, (e: any) => {
      console.log('Audio decoding error:', e);
    });
  }

  const getChatHistory = async () => {
    const lastChatId = messages?.[0]?.id || 0
    if (lastChatId === lastGetChatId) return
    setLastGetChatId(lastChatId)
    const data = await getChats(config.window_id, lastChatId, 20, isEdit ? 1 : 0)
    let prevMessages: IMessage[] = []

    console.log('lastChatId', lastChatId, 'data?.length', data?.length, 'res', lastChatId === 0 && !data?.length)
    if (lastChatId === 0 && !data?.length) {
      prevMessages = [{
        content: config.introduction,
        type: msgType.TEXT,
        role: roleType.GPT,
        costSeconds: 0,
        tokens: 0,
        totalTokens: 0
      }]
    } else {
      prevMessages = data?.reverse().reduce((msgs: any, msg: any) => {
        const curMsgs = []
        if (msg?.question) {
          curMsgs.push({
            id: msg?.id,
            content: msg?.question,
            type: msgType.TEXT,
            role: roleType.ME,
          })
        }
  
        if (msg?.answer) {
          curMsgs.push({
            id: msg?.id,
            content: msg?.answer,
            type: msg?.msg_type,
            role: roleType.GPT,
            costSeconds: Math.ceil(msg?.cost_seconds),
            tokens: msg?.total_tokens,
            totalTokens: msg?.shiflow_total_tokens,
            autoPlay: false,
            imageModel: msg?.image_model,
          })
        }
  
        return [
          ...msgs,
          ...curMsgs,
        ]
      }, [])
    }

    setMessages(messages => {
      return [...prevMessages, ...messages]
    })
  }

  const initSocket = (): Promise<WebSocket> => {
    if (ws) ws.close()
    return new Promise(resolve => {
      const wsUrl = isEdit
        ? `ws://${API_HOST}/ws/shiflow/admin/stream/chats`
        : `ws://${API_HOST}/ws/shiflow/stream/chats`

      const webSocket = new WebSocket(wsUrl)

      webSocket.onopen = () => {
        console.log('WebSocket 连接已打开')
        resolve(webSocket)
      }

      webSocket.onmessage = (event) => {
        const { data, is_end, msg_type } = JSON.parse(event.data)

        // if (msg_type === msgType.VOICE_STREAM) {
        //   decodeData(data)
        //   if (is_end) {
        //     let completedMessage = {}
        //     setUncompletedMessage(() => {
        //       completedMessage = {
        //         content: '',
        //         type: msg_type,
        //         duration: Math.round(data?.voice_duration),
        //         role: roleType.GPT,
        //         costSeconds: Math.ceil(data?.cost_seconds),
        //         tokens: data?.shiflow_guess_tokens,
        //       }
        //       return {}
        //     })
        //     setIsPending(false)
        //     setMessages(messages => [...messages, completedMessage])
        //   } else {
        //     if (!uncompletedMessage) {
        //       setUncompletedMessage(() => {
        //         return {
        //           content: '',
        //           type: msg_type,
        //           role: roleType.GPT,
        //         }
        //       })
        //     }
        //   }
        // }

        if (is_end) {
          clearHeartbeat()
          let completedMessage = {} as IMessage
          setUncompletedMessage(uncompletedMessage => {
            completedMessage = {
              content: (uncompletedMessage?.content ?? '') + data?.content,
              type: uncompletedMessage?.type ?? msg_type,
              duration: Math.round(data?.voice_duration),
              role: roleType.GPT,
              costSeconds: Math.ceil(data?.cost_seconds),
              tokens: data?.total_tokens,
              totalTokens: data?.shiflow_total_tokens,
              imageModel: data?.image_model,
            }
            return {}
          })
          setIsPending(false)
          setMessages(messages => {
            if (messages?.filter(msg => msg.role === roleType.ME)?.length <= 1) onFirstAnswer?.(messages?.filter(msg => msg.role === roleType.ME)?.[0]?.content)
            return completedMessage?.content ? [...messages, completedMessage] : messages
          })
        } else {
          setUncompletedMessage(uncompletedMessage => {
            return {
              content: (uncompletedMessage.content ?? '') + data?.content,
              type: uncompletedMessage.type ?? msg_type,
              role: roleType.GPT,
            }
          })
        }
        console.log('收到消息:', JSON.parse(event.data))
      }

      webSocket.onerror = (error) => {
        console.log('WebSocket 出错:', error)
        setIsPending(false)
      }

      webSocket.onclose = function (event) {
        console.log("WebSocket 连接已关闭：", event);
        setIsPending(false)
      }

      setWs(webSocket)
    })
  }

  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      elementScrollIntoView(messagesEndRef.current, { block: 'end', behavior: 'smooth' })
    }
  };

  const onInput = (event: any) => {
    const value = event?.target?.value ?? ''
    setMessage(value)
  }

  const onKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey && !isCompositingRef.current) {
      event.preventDefault()
      sendMessage()
    }
  };

  const sendMessage = async () => {
    if (isPending) return
    let readyWebsocket = ws
    if (ws?.readyState === WebSocket.CLOSED) {
      console.log('Websocket 断线重连')
      readyWebsocket = await initSocket()
    }

    if (message.trim()) {
      const curMessages = [...messages, {
        content: message,
        type: msgType.TEXT,
        role: roleType.ME,
      }]
      setMessages(curMessages)
      setMessage('')

      let msg_type = 'text'
      // if (config.image_model === 'dalle' || config.image_model === 'midjourney') msg_type = 'image'
      // else if (config.voice_model === 'tts' || config.voice_model === 'elevenlabs') msg_type = 'voice'

      const messageData = isEdit
        ? {
          msg_type,
          data: {
            question: message,
            token: store.token,
            app_id: config.id,
            system_prompt: config.system_prompt,
            chat_model: config.chat_model,
            image_model: config.image_model,
            voice_model: config.voice_model,
            voice_ask_model: config.voice_ask_model,
          },
        }
        : {
          msg_type,
          data: {
            question: message,
            token: store.token,
            app_id: config.id,
            window_id: config.window_id,
          }
        }

      console.log('发送消息', messageData)
      readyWebsocket?.send(JSON.stringify(messageData))
      createHeartbeat(readyWebsocket)
      setIsPending(true)
    }
  };

  const onHeightChange = () => {
    scrollToBottom()
  }

  const onScrollToBottom = () => {
    scrollToBottom()
  }

  const onCopy = (message: IMessage, index: number) => {
    try {
      const textarea = document.createElement('textarea')
      textarea.value = message?.content ?? ''
      textarea.style.position = 'fixed'
      textarea.style.opacity = '0'
      document.body.appendChild(textarea)
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea)
      setCopyIndex(index)
    } catch { }

    setTimeout(() => setCopyIndex(-1), 2000)
  }

  const renderMessage = (message: IMessage) => {
    switch (message.type) {
      case msgType.IMAGE:
        return <>
          <img className="message-image" src={message.content} alt="" />
          {
            message.imageModel === MODEL.MIDJOURNEY && <>
              <p className="message mt-[10px]">返回四宫格图片</p>
              <p className="message mt-[5px]"><span className="num-space">1</span><span className="num-space">2</span></p>
              <p className="message mb-[5px]"><span className="num-space">3</span><span className="num-space">4</span></p>
              <p className="message">回复U+编号查看大图，例：U1</p>
              <p className="message">回复V+编号，按照该图再次重新生成，例：V1</p>
            </>
          }
        </>
      case msgType.VOICE:
      case msgType.VOICE_STREAM:
        return <AudioBubble audioSrc={message.content as string} duration={message.duration} autoPlay={message.autoPlay} />
      default:
        return (
          <p className="message">
            <Markdown components={{
              // @ts-ignore
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  // @ts-ignore
                  <SyntaxHighlighter
                    {...props}
                    style={oneDark}
                    language={match[1]}
                    PreTag="div"
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code {...props} className={className}>
                    {children}
                  </code>
                );
              },
            }}>{message.content}</Markdown>
          </p>
        );
    }
  }

  const createHeartbeat = (ws: WebSocket | null) => {
    if (wsTimer) clearInterval(wsTimer)
    let count = 0
    wsTimer = setInterval(() => {
      if (count >= 10) {
        clearInterval(wsTimer)
      }
      count++
      ws?.send('ping')
    }, HEARTBEAT_DURATION)
  }

  const clearHeartbeat = () => {
    if (wsTimer) clearInterval(wsTimer)
  }

  return (
    <div className="wrapper-chat-base">
      {title && <h1 className="chat-title">{title}</h1>}
      <div className="chat-list">
        <div ref={messagesStartRef} />
        {
          messages?.map((message, index) => {
            return !message.isSplit
              ? <div className="chat-item" key={index}>
                {
                  message.role === roleType.ME
                    ? <Avatar classes={{ root: 'avatar' }}>ME</Avatar>
                    : <Avatar classes={{ root: 'avatar' }} src={config.icon} />
                }
                <div className="wrapper-message">
                  <div className="nickname">{message.role === roleType.ME ? '我' : config.name}</div>
                  {renderMessage(message)}
                  {
                    message.role === roleType.GPT && (!!message.costSeconds || !!message.tokens || !!message.totalTokens)
                    && <div className="message-tips">
                      <span className="tips-item">{message.costSeconds || '0'}s</span>
                      <span className="split"></span>
                      <span className="tips-item">{message.tokens || '0'} Tokens</span>
                      <span className="split"></span>
                      <span className="tips-item">{message.totalTokens || '0'} 算力</span>
                      {
                        message.type === msgType.TEXT && <>
                          {
                            copyIndex !== index
                              ? <ContentCopyIcon className="icon-copy" onClick={onCopy.bind(null, message, index)} />
                              : <CheckIcon className="icon-copyed" />
                          }
                        </>
                      }
                    </div>
                  }
                </div>
              </div>
              : <div className="split">模型已更新</div>
          })
        }
        {
          (isPending || uncompletedMessage.content) && <div className="chat-item">
            <Avatar classes={{ root: 'avatar' }} src={config.icon} />
            <div className="wrapper-message">
              <div className="nickname">{config.name}</div>
              <p className="message">
                {uncompletedMessage.content}<span className={classNames('input-cursor', { pending: !uncompletedMessage.content })} />
              </p>
            </div>
          </div>
        }
        <div ref={messagesEndRef} />
      </div>
      <Tooltip title={isPending && message?.length ? '对话生成中，请稍后发送消息' : ''} placement="top">
        <div className="wrapper-input">
          {(isShowScrollButton && !uncompletedMessage.content) && <KeyboardDoubleArrowDownIcon className="button-reach-bottom" onClick={onScrollToBottom} />}
          <TextareaAutosize
            minRows={1}
            placeholder="请输入你的问题"
            className="message-input"
            value={message}
            onInput={onInput}
            onCompositionStart={() => isCompositingRef.current = true}
            onCompositionEnd={() => isCompositingRef.current = false}
            onKeyDown={onKeyDown}
            onHeightChange={onHeightChange}
          />
          <div
            className={classNames('message-button', { active: message.length > 0 && !isPending })}
            onClick={sendMessage}
          >
            <ArrowUpwardIcon className="icon-send" />
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export default ChatBase;
