export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  if (i === 0) {
    return parseFloat(bytes.toFixed(dm)) + ' ' + sizes[i];
  }

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatTimestamp = (timestamp: number) => {
  let date = new Date(timestamp); // 创建一个新的Date对象

  let year: string | number = date.getFullYear(); // 获取年份
  let month: string | number = date.getMonth() + 1; // 获取月份，月份是从0开始的，所以要加1
  let day: string | number = date.getDate(); // 获取日

  let hours: string | number = date.getHours(); // 获取小时
  let minutes: string | number = date.getMinutes(); // 获取分钟
  let seconds: string | number = date.getSeconds(); // 获取秒

  // 如果月份、日、小时、分钟或秒数小于10，则在前面添加一个'0'
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  // 拼接字符串
  return year + '年' + month + '月' + day + '日 ' + hours + ':' + minutes + ':' + seconds;
}

export const isWeixin = () => {
  return /MicroMessenger/i.test(navigator.userAgent);
}