import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Button,
  CircularProgress,
  Drawer,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { publishApplication, removeApplication, updateApplication } from '../../apis';
import { MODEL, MODEL_PRICE } from '../../lib/constants';
import { useContactPopup } from '../../pages/apps-layout/applist/components/contact';
import Store from '../../stores/index';
import ConfirmModal from '../confirm-modal';
import RetButton from '../ret-button';
import './index.scss';


interface ITopNav {
  config: any;
  onClearchat: () => void
}

function TopNav({ config = {}, onClearchat }: ITopNav) {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [drawerPublishOpen, setDrawerPublishOpen] = useState(false);
  const [menuSaveOpen, setMenuSaveOpen] = useState(false);
  const [menuMoreOpen, setMenuMoreOpen] = useState(false);
  const [publishType, setPublishType] = useState(0);
  const [anchorSaveEl, setAnchorSaveEl] = useState<null | HTMLElement>(null);
  const [anchorMoreEl, setAnchorMoreEl] = useState<null | HTMLElement>(null);
  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [_contactPopup, { show: showContactPopup, hide: hideContactPopup }] = useContactPopup();
  const [app, setApp] = useState<Record<string, any>>(config)
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false)
  const [clearConfirmOpen, setClearConfirmOpen] = useState(false)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    setApp(config)
  }, [config]);

  const updateApp = (newValue: any) => {
    setApp((app: any) => {
      return {
        ...app,
        ...newValue,
      }
    })
  }

  const onClickMore = (event: any) => {
    setMenuMoreOpen(true);
    setAnchorMoreEl(event?.currentTarget);
  };

  const onCloseMore = () => {
    setMenuMoreOpen(false);
    setAnchorMoreEl(null);
  };

  const onClickSave = (event: any) => {
    setMenuSaveOpen(true);
    setAnchorSaveEl(event?.currentTarget);
  };

  const onCloseSave = () => {
    setMenuSaveOpen((state) => !state);
    setAnchorSaveEl(null);
  };

  const onRadioChange = (event: any) => {
    setPublishType(event?.target?.value);
  };

  const onSave = async () => {
    if (Number(publishType) === 0) {
      setSaving(true);
      const data = await updateApplication(app.id, {
        id: app.id,
        name: app.name,
        icon: app.icon,
        system_prompt: app.system_prompt,
        chat_model: app.chat_model,
        image_model: app.image_model,
        voice_model: app.voice_model,
        voice_ask_model: app.voice_ask_model,
      });
      console.log('保存应用', data);
      Store.openAlert('已保存AI')
      setMenuSaveOpen(false);
      setSaving(false);
      navigate(`/gpts/editor/${app.id}`, { replace: true });
    } else if (Number(publishType) === 1) {
      setMenuSaveOpen(false);
      setDrawerPublishOpen(true);
    }
  };

  const onPublish = async () => {
    setPublishing(true);
    const data = await publishApplication(app.id, {
      id: app.id,
      name: app.name,
      icon: app.icon,
      system_prompt: app.system_prompt,
      chat_model: app.chat_model,
      image_model: app.image_model,
      voice_model: app.voice_model,
      voice_ask_model: app.voice_ask_model,
      introduction: app.introduction,
      free_count: Number(app.free_count) || 0,
      gpt3_price: Number(app.gpt3_price) || 0,
      gpt4_price: Number(app.gpt4_price) || 0,
      wxyy_price: Number(app.wxyy_price) || 0,
      wxyy_turbo_price: Number(app.wxyy_turbo_price) || 0,
      wxyy_free_price: Number(app.wxyy_free_price) || 0,
      minimax5_price: Number(app.minimax5_price) || 0,
      minimax6_price: Number(app.minimax6_price) || 0,
      yujuai_price: Number(app.yujuai_price) || 0,
      doubao_lite_price: Number(app.doubao_lite_price) || 0,
      doubao_pro_price: Number(app.doubao_pro_price) || 0,
      dalle_price: Number(app.dalle_price) || 0,
      midjourney_price: Number(app.midjourney_price) || 0,
      midjourney_upscale_price: Number(app.midjourney_upscale_price) || 0,
      sd3_price: Number(app.sd3_price) || 0,
    });
    console.log('发布应用', data);
    Store.openAlert('已发布AI')
    setDrawerPublishOpen(false);
    setPublishing(false);
    navigate(`/apps/${app.id}`);
  };

  // AI简介
  const onIntroduction = (e: any) => {
    updateApp({
      introduction: e?.target?.value
    })
  };

  // 返回
  const onBack = () => {
    navigate(-1);
  };

  // 删除AI
  const onClickRemove = () => {
    setRemoveConfirmOpen(true)
  }

  // 取消删除
  const onCancelRemove = () => {
    setRemoveConfirmOpen(false)
  }

  // 确认删除
  const onConfirmRemove = async () => {
    setPending(true)
    const isSuccess = await removeApplication(app.id)
    if (isSuccess) {
      navigate(`/apps`, { replace: true });
    } else {
      setRemoveConfirmOpen(false)
      setPending(false)
    }
  }

  // 清空对话记录
  const onClickClear = () => {
    setClearConfirmOpen(true)
  }

  // 取消清空
  const onCancelClear = () => {
    setClearConfirmOpen(false)
  }

  // 确认清空
  const onConfirmClear = async () => {
    setPending(true)
    onClearchat()
    setClearConfirmOpen(false)
    setMenuMoreOpen(false)
    setPending(false)
  }

  const getInputItem = (key: string, endAdornmentText: string, appendText?: string) => {
    return <>
      <OutlinedInput
        value={app[key]}
        size="small"
        classes={{
          root: 'input-root',
          notchedOutline: 'input-outline',
          input: 'input-font',
        }}
        endAdornment={
          <InputAdornment
            position="end"
            classes={{
              root: 'input-font',
            }}
          >
            {endAdornmentText}
          </InputAdornment>
        }
        onInput={(e: any) => {
          if (e?.target?.value && !/^\d+$/.test(e?.target?.value)) return
          updateApp({
            [key]: e?.target?.value
          })
        }}
        onBlur={(e: any) => {
          if (e?.target?.value) return
          updateApp({
            [key]: 0
          })
        }}
      />
      {appendText && <span className="append-text">{appendText}</span>}
    </>
  }

  return (
    <>
      {_contactPopup}
      <header className="wrapper-top-nav">
        <div className="wrapper-title">
          <RetButton className="button-back" onClick={onBack} />
          {id ? app.name : '创建AI'}
        </div>
        <div>
          <Button className="button-more" onClick={onClickMore}>
            <MoreHorizIcon className="icon-more" />
          </Button>
          <Menu
            id="menu-more"
            anchorEl={anchorMoreEl}
            open={menuMoreOpen}
            onClose={onCloseMore}
            classes={{
              paper: 'top-nav-menu more',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={onClickClear}>清空对话记录</MenuItem>
            {id && <MenuItem onClick={onClickRemove}>删除AI</MenuItem>}
          </Menu>
          <Button
            className="button-save"
            variant="contained"
            endIcon={<ArrowDropDownIcon />}
            aria-controls={menuSaveOpen ? 'menu-save' : undefined}
            aria-haspopup="true"
            aria-expanded={menuSaveOpen}
            onClick={onClickSave}
          >
            保存和发布AI
          </Button>
          <Menu
            id="menu-save"
            anchorEl={anchorSaveEl}
            open={menuSaveOpen}
            onClose={onCloseSave}
            classes={{
              paper: 'top-nav-menu',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <RadioGroup name="customized-radios" value={publishType} defaultValue={0} onChange={onRadioChange}>
              <FormControlLabel
                value={0}
                control={<Radio className="menu-save-radio" />}
                label="保存AI（仅自己可见）"
              />
              <FormControlLabel
                value={1}
                control={<Radio className="menu-save-radio" />}
                label="发布AI（所有人可见）"
              />
            </RadioGroup>
            <Button
              variant="contained"
              classes={{
                root: 'menu-save-confirm',
                disabled: 'confirm-disable',
              }}
              fullWidth
              onClick={onSave}
              disabled={!config.name || saving}
            >
              {saving ? <CircularProgress size={20} color="inherit" /> : '确定'}
            </Button>
          </Menu>
        </div>
        <Drawer
          open={drawerPublishOpen}
          anchor={'right'}
          classes={{
            paper: 'wrapper-drawer'
          }}
          onClose={() => setDrawerPublishOpen(false)}
        >
          <div className="top-nav-publish-drawer">
            <div className="wrapper-info">
              {/* <Avatar classes={{ root: 'avatar' }} src={app.icon}></Avatar> */}
              {app.name}
            </div>
            <label className="label" htmlFor="">
              AI简介
            </label>
            <OutlinedInput
              value={app.introduction}
              multiline
              rows={3}
              placeholder="一句话介绍下你的AI吧"
              classes={{
                root: 'input-root',
                notchedOutline: 'input-outline',
                input: 'input-font',
              }}
              onInput={onIntroduction}
              fullWidth
            />
            <label className="label" htmlFor="">
              免费试用次数
            </label>
            <div className="tips">
              你的用户可以免费试用AI的次数，试用完之后，需要充值继续使用，您将获得充值消费的收益
            </div>
            {getInputItem('free_count', '次')}
            <label className="label" htmlFor="">
              设定价格
            </label>
            <div className="tips">
              您可在成本基础上增加或减少，设定用户消耗的算力，定价超过成本部分全部给到创作者
            </div>
            {
              config.chat_model === MODEL.GPT3 && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.GPT3]}算力/1000tokens
                </div>
                {getInputItem('gpt3_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.chat_model === MODEL.GPT4 && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.GPT4]}算力/1000tokens
                </div>
                {getInputItem('gpt4_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.chat_model === MODEL.WXYY && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.WXYY]}算力/1000tokens
                </div>
                {getInputItem('wxyy_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.chat_model === MODEL.WXYY_TURBO && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.WXYY_TURBO]}算力/1000tokens
                </div>
                {getInputItem('wxyy_turbo_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.chat_model === MODEL.WXYY_FREE && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.WXYY_FREE]}算力/1000tokens
                </div>
                {getInputItem('wxyy_free_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.chat_model === MODEL.MINIMAX5 && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.MINIMAX5]}算力/1000tokens
                </div>
                {getInputItem('minimax5_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.chat_model === MODEL.MINIMAX6 && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.MINIMAX6]}算力/1000tokens
                </div>
                {getInputItem('minimax6_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.chat_model === MODEL.YUJUAI && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.YUJUAI]}算力/1000tokens
                </div>
                {getInputItem('yujuai_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.chat_model === MODEL.DOUBAO_LITE && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.DOUBAO_LITE]}算力/1000tokens
                </div>
                {getInputItem('doubao_lite_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.chat_model === MODEL.DOUBAO_PRO && <>
                <div className="tips tips-label">
                  <strong>对话模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.DOUBAO_PRO]}算力/1000tokens
                </div>
                {getInputItem('doubao_pro_price', '算力', '/ 1000tokens')}
              </>
            }
            {
              config.image_model === MODEL.DALLE && <>
                <div className="tips tips-label">
                  <strong>文生图模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.DALLE]}算力/图片生成
                </div>
                {getInputItem('dalle_price', '算力', '/ 图片生成')}
              </>
            }
            {
              config.image_model === MODEL.MIDJOURNEY && <>
                <div className="tips tips-label">
                  <strong>文生图模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.MIDJOURNEY]}算力/4格图片生成，{MODEL_PRICE[MODEL.MIDJOURNEY_UPSCALE]}算力/图片放大
                </div>
                {getInputItem('midjourney_price', '算力', '/ 4格图片生成')}
                <div className="input-placeholder"></div>
                {getInputItem('midjourney_upscale_price', '算力', '/ 图片放大')}
              </>
            }
            {
              config.image_model === MODEL.SD3 && <>
                <div className="tips tips-label">
                  <strong>文生图模型价格：</strong>当前成本为{MODEL_PRICE[MODEL.SD3]}算力/图片生成
                </div>
                {getInputItem('sd3_price', '算力', '/ 图片生成')}
              </>
            }
            {/* <label className="label" htmlFor="">
              设置域名
            </label>
            <div className="tips">域名设置后不可修改</div>
            <OutlinedInput
              size="small"
              classes={{
                root: 'input-root',
                notchedOutline: 'input-outline',
                input: 'input-font',
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  classes={{
                    root: 'input-font',
                  }}
                >
                  .shiflow.com
                </InputAdornment>
              }
            />
            <label className="label" htmlFor="" onClick={showContactPopup}>
              绑定微信生态（未开通）
            </label>
            <div className="tips">
              您的AI可以绑定到个人微信、企业微信、微信公众号、小程序等微信生态私域运营工具，添加工作人员咨询详情(shierforwork)
            </div> */}
            <Button
              size="large"
              variant="contained"
              classes={{
                root: 'button-publish',
                disabled: 'publish-disabled',
              }}
              fullWidth
              onClick={onPublish}
              disabled={publishing}
            >
              {publishing ? <CircularProgress size={20} color="inherit" /> : '发布（所有人可见）'}
            </Button>
          </div>
        </Drawer>
      </header>
      <ConfirmModal
        open={removeConfirmOpen}
        title={'确认删除应用?'}
        onCancel={onCancelRemove}
        onConfim={onConfirmRemove}
        confirmText={'确认删除'}
        pending={pending}
      />
      <ConfirmModal
        open={clearConfirmOpen}
        title={'确认清空对话记录?'}
        onCancel={onCancelClear}
        onConfim={onConfirmClear}
        confirmText={'确认清空'}
        pending={pending}
      />
    </>
  );
}

export default TopNav;
