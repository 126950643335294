import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IconAvatar from '../../assets/icons/icon-avatar.svg';
import IconShiflowAll from '../../assets/icons/icon-shiflow-all.svg';
import './index.scss';

function Home({ store }: any) {
  const navigate = useNavigate();

  const onProfile = () => {
    navigate('/appstore');
  }
  return (
    <div className="wrapper-home">
      <div className="home-nav">
        <img className="logo" src={IconShiflowAll} alt="" />
        <div className="wrapper-right">
          <a className="menu-item" href="/appstore" rel="noreferrer">AI应用广场</a>
          <a className="menu-item" href="https://blog.shiflow.com/shuishan/" target="_blank" rel="noreferrer">AI落地咨询</a>
          {/* <a className="menu-item" href="https://shiflowai.feishu.cn/share/base/form/shrcn9swU4ISQNWy1IPYIaQtx0e" target="_blank" rel="noreferrer">内测申请</a> */}
          <a className="menu-item" href="http://blog.shiflow.com" target="_blank" rel="noreferrer">识流AI专业版</a>
          <img className="icon-avatar" src={IconAvatar} alt="" onClick={onProfile} />
        </div>
      </div>
      <div className="home-detail">
        <div className="wrapper-title">
          {/* <h1 className="title">识流AI</h1> */}
          <h1 className="title">识流AI，每个人的AI智能体</h1>
          <h2 className="subtitle">帮企业和流量操盘手，训练AI并接入公众号、小程序、抖音。独有邀请码充值系统，帮企业和操盘手快速变现。</h2>
          {
            <Button className="button" variant="contained" onClick={onProfile}>
              立即体验
            </Button>
          }
        </div>
        <img className="logo" src="https://shiflow-gpts.oss-cn-beijing.aliyuncs.com/shishang.gif" alt="" />
        <Button className="button-mobile" variant="contained" onClick={onProfile}>
          立即体验
        </Button>
      </div>
      <footer className="footer">
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">京ICP备2023011855号</a>｜<a href="https://beian.mps.gov.cn/#/query/webSearch?code=11-1-802042627" target="_blank" rel="noreferrer">京公网安备 10010802042627号</a>｜©️ 2024 识流 shiflow.com
      </footer>
      {/* <header className="header">
        <div>
          <h1 className="title">帮行业专家，基于行业知识，构建自己的私域AI服务</h1>
          <h2 className="subtitle">
            已经帮助100+行业专家构建公众号、企业微信、微信、小程序、抖音等AI私域服务，完成变现
          </h2>
          <Button className="button-create-ai" variant="contained" onClick={onCreateAI}>
            创建AI
          </Button>
        </div>
      </header>
      <div className="search-wrapper">
        <div className="card-list-title">广场</div>
        <Paper className="search-textfield" component="form">
          <InputBase className="search-input" placeholder="搜索AI应用" />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <div className="card-list">
        {new Array(21).fill(0).map(() => (
          <Card className="card">
            <CardActionArea className="card-area">
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Lizard
                </Typography>
                <Typography variant="body2">species, ranging across all continents except Antarctica</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </div> */}
    </div>
  );
}

export default Home;
