import { Avatar, Button } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import wx from 'weixin-js-sdk-ts';
import { createPay, getAuth, getPayConfig, getSelfFund } from '../../apis';
import './index.scss';

const AppIds: Record<string, string> = {
  shiflow: 'wxf1c89d9f6f68fd5d', // 识流正式
  shiflowtest: 'wxea895986e49d2c01', // 识流测试
  destiny: 'wxfe0e2c6de729c2e4', // 博物都知道
  aigf: 'wxbc0d8cbc3858f40b', // 未来心跳
}

function Recharge({ store }: any) {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const code = queryParams.get('code') || ''
  const state = queryParams.get('state') || ''
  const source = queryParams.get('source') || ''
  const test = queryParams.get('test') || ''
  const appid = AppIds[source] || ''
  const [isAccount, setIsAccount] = useState(true)
  const [openid, setOpenid] = useState('')
  const [avatar, setAvatar] = useState('')
  const [nickname, setNickname] = useState('')
  const [amountConfig, setAmountConfig] = useState<Record<string, any>[]>([])
  const [curAmountIndex, setCurAmountIndex] = useState(0)
  const [fund, setFund] = useState<Record<string, string>>({})

  useLayoutEffect(() => {
    // 授权成功时，回调页面参数会带code参数
    if (code) {
      initAuth(code)
      initPayConfig()
    }
    // 未授权时，跳转微信授权链接
    if (state !== 'authorized') {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=authorized#wechat_redirect`;
    }
  }, [])

  useEffect(() => {
    document.title = '我的账户'
  }, [])

  // 获取登录信息
  const initAuth = async (code: string) => {
    const { openid, headimgurl, nickname, access_token } = await getAuth(code, source)
    setOpenid(openid)
    setAvatar(headimgurl)
    setNickname(nickname)
    store.setToken(access_token)
    initFund()
  }

  // 获取账户余额信息
  const initFund = async () => {
    const fund = await getSelfFund()
    setFund(fund)
  }

  // 初始化微信支付配置
  const initPayConfig = async () => {

    const { price_config: amountConfig, ...config } = await getPayConfig(source)
    setAmountConfig(amountConfig)
    if (test === '1') setAmountConfig([
      {
        tokens: 1,
        amount: 1,
      }
    ])
    wx.config({
      ...config,
      jsApiList: ['chooseWXPay'],
    })
    // wx.ready(() => alert(JSON.stringify(config)));
  }

  const onChooseAmount = (index: number) => {
    setCurAmountIndex(index)
  }

  const onRecharge = async () => {
    const amount = amountConfig[curAmountIndex]?.amount
    const { timeStamp, ...payInfo } = await createPay(openid, amount, source)
    wx.chooseWXPay({
      ...payInfo,
      timestamp: Number(timeStamp),
      success: async () => {
        await initFund()
        setIsAccount(true)
        document.title = '我的账户'
      },
      fail: () => { }
    })
  }

  return (
    <div className="wrapper-recharge">
      {
        isAccount
          ? <>
            <div className="wrapper-user">
              <Avatar className="user-avatar" src={avatar}></Avatar>
              <div className="user-nickname">{nickname}</div>
            </div>
            <div className="wrapper-account">
              <div className="account-title">识流算力余额</div>
              <div className="acount-balance">{fund?.sf_balance || 0}</div>
            </div>
            <div className="wrapper-button-recharge">
              <Button
                size="large"
                variant="contained"
                className="button-recharge"
                fullWidth
                onClick={() => {
                  setIsAccount(false)
                  document.title = '充值'
                }}
              >
                充值
              </Button>
            </div>
          </>
          : <>
            <div className="recharge-title">套餐选择</div>
            {
              amountConfig.map((config, index) =>
                <div
                  className={classNames('amount-item', { active: curAmountIndex === index })}
                  onClick={onChooseAmount.bind(null, index)}
                  key={index}
                >
                  <span>{config.tokens} 算力</span>
                  <span>￥{config.amount / 100}</span>
                </div>
              )
            }
            <div className="recharge-desc">充值算力，可在所用的AI模型、应用中使用，根据使用量扣除算力</div>
            <div className="wrapper-button-recharge">
              {/* <Button
                size="large"
                variant="outlined"
                className="button-back"
                fullWidth
                onClick={() => {
                  setIsAccount(true)
                  document.title = '我的账户'
                }}
              >
                查看账户余额
              </Button> */}
              <Button
                size="large"
                variant="contained"
                className="button-recharge"
                fullWidth
                onClick={onRecharge}
              >
                支付<span className="recharge-amount">￥{(amountConfig[curAmountIndex]?.amount / 100) || ''}</span>
              </Button>
            </div>
          </>
      }
    </div>
  )
}

export default Recharge