import { Backdrop, Box, CircularProgress, Fade, Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { getLoginQRCode, getLoginStatus, getSelf } from '../../apis';
import Store from '../../stores/index';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'fixed' as 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '32px',
  bgcolor: '#3D404A',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px',
};

export const WeixinQRCodeModal: React.FC<{
  isLogin?: boolean;
  onChangeLogin?: () => void;
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose, isLogin, onChangeLogin }) => {
  const navigate = useNavigate();
  const { data, loading, error, run } = useRequest<
    {
      body: {
        nonce: string;
        ticket: string;
      };
    },
    any
  >(getLoginQRCode);
  useEffect(() => {
    let timer: any;
    const fetchLoginStatus = async (retryCount = 0) => {
      if (!data?.body?.nonce) {
        return;
      }
      if (retryCount > 20) {
        return;
      }
      const resp = await getLoginStatus(data.body.nonce);
      if (resp?.body?.access_token) {
        Store.setToken(resp.body.access_token);
        const selfInfo = await getSelf();
        const { account_type: accountType, switch_code: switchCode } = selfInfo;
        Store.setShowLogin(false);
        Store.setSelfInfo(selfInfo);
        Store.setShowLogin(false);
        Store.setAccountType(accountType);
        Store.setSwitchCode(switchCode);
        Store.openAlert('登录成功');
        if (Store.callbackUrl) {
          navigate(Store.callbackUrl);
          Store.setCallbackUrl('');
        }
        return;
      }
      timer = setTimeout(() => {
        fetchLoginStatus(retryCount + 1);
      }, 1000);
    };
    fetchLoginStatus();
    return () => {
      clearTimeout(timer);
    };
  }, [data?.body?.nonce]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} display="flex" alignItems="center" flexDirection="column">
          <div className="text-white" style={{ marginBottom: 25 }}>
            微信扫码{isLogin ? '登录' : '绑定'}
          </div>
          {loading && !data?.body && (
            <div
              style={{
                width: 150,
                height: 150,
              }}
              className="flex items-center justify-center"
            >
              <CircularProgress />
            </div>
          )}
          {data?.body && (
            <img
              width={150}
              height={150}
              src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${data.body.ticket}`}
            />
          )}
          <div
            style={{
              marginTop: 25,
              width: 210,
              fontSize: '10px',
            }}
            className="text-white"
          >
            {isLogin ? (
              <>
                <span style={{ color: '#3e8ce2' }} className="cursor-pointer underline" onClick={onChangeLogin}>
                  账号密码登录
                </span>
                （内测用户请先使用账号密码登录，再通过微信扫码绑定账号）
              </>
            ) : (
              <span>重要！微信扫码后，可以将内测账号与微信账号绑定，并继承已有的算力和权限</span>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export const useWeixinLoginModal = () => {
  const [visible, setVisible] = useState(false);
  return [
    () => (!visible ? null : <WeixinQRCodeModal open={visible} isLogin={false} onClose={() => setVisible(false)} />),
    setVisible,
  ] as const;
};
