import { Drawer } from '@mui/material';
import { ReactNode, useState } from "react";
import './index.scss';

interface IDrawerHooks {
  title?: ReactNode
}

interface IDrawer {
  children?: ReactNode
}

export const useDrawer = ({ title }: IDrawerHooks) => {
  const [visible, setVisible] = useState(false);

  return [
    ({ children }: IDrawer) => (
      <Drawer
        open={visible}
        anchor={'right'}
        classes={{
          paper: 'wrapper-drawer-hooks'
        }}
        onClose={() => setVisible(false)}
      >
        {title && <h1 className="drawer-hooks-title">{title}</h1>}
        {children}
      </Drawer>
    ),
    setVisible
  ] as const;
};