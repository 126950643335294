import { useParams } from 'react-router-dom';
import HeadBar from '../../../components/head-bar';
import { useRequest, useSafeState } from 'ahooks';
import { getApplication } from '../../../apis';
import React, { useState } from 'react';
import { Button, StyledEngineProvider } from '@mui/material';
import './index.scss';
import WorkproBindDialog from './components/workpro-bind-dialog';

const WeixinBind = (props: {}) => {
  return (
    <StyledEngineProvider injectFirst>
      <div className="wrapper-profile h-screen min-w-full">
        <Content />
      </div>
    </StyledEngineProvider>
  );
};

const Content = () => {
  const { appid } = useParams();
  const { data, loading } = useRequest(getApplication, {
    defaultParams: [appid!],
    ready: !!appid,
  });
  const [showBind, setShowBind] = useState(false);
  if (!data || loading) {
    return null;
  }
  return (
    <section className="flex h-full  flex-col overflow-hidden">
      <WorkproBindDialog visible={showBind} onClose={() => setShowBind(false)} />
      <HeadBar appInfo={data} />
      <div className="mx-auto w-[1200px] flex-1 overflow-x-hidden">
        <section>
          <div className="bold mt-[40px] text-[20px] text-white">绑定企业微信</div>
          <div className="mt-[30px] text-[15px] text-[#A0A0A0]">
            请使用企业微信扫码登录绑定，首次绑定会有安全提示，通过验证码身份验证后，之后可以有稳定绑定状态。
          </div>
        </section>
        <section className="mr-[-30px] mt-[60px] flex flex-wrap">
          {Array.from({ length: 5 }).map((_, index) => {
            return (
              <div
                key={index}
                className="rounded-10px mb-[30px] mr-[30px] flex h-[100px] w-[380px] items-center rounded-[10px] border-[1px] border-solid border-white bg-[#3D404A]"
              >
                <img
                  className="ml-[24px] h-[50px] w-[50px]"
                  src={require('../../../assets/icons/icon-workpro-line.svg').default}
                  alt=""
                />
                <img
                  width="99px"
                  className="ml-[20px]"
                  src={require('../../../assets/icons/icon-skeleton-line.svg').default}
                  alt=""
                />
                <Button
                  onClick={() => {
                    setShowBind(true);
                  }}
                  className="ml-[40px] h-[50px] w-[115px] rounded-[5px] text-[20px]"
                  variant="contained"
                >
                  企微扫码
                </Button>
              </div>
            );
          })}
        </section>
      </div>
    </section>
  );
};

export default WeixinBind;
