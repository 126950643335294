import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const PublishStatusTag: React.FC<{
  published?: boolean;
  className?: string;
}> = ({ published, className }) => {
  return (
    <span
      className={classNames('flex items-center justify-center', styles.tag, className, {
        [styles.published]: published,
      })}
    >
      {published ? '已发布' : '未发布'}
    </span>
  );
};

export default PublishStatusTag;
