import { Button, Dialog } from '@mui/material';
import { FormEvent, FormEventHandler } from 'react';
import classNames from 'classnames';

const WorkproBindDialog: React.FC<{
  visible: boolean;
  onClose: () => void;
}> = ({ visible, onClose }) => {
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    // @ts-ignore
    if (!e.target.code.value) {
      return;
    }
  };
  return (
    <Dialog
      onClose={onClose}
      open={visible}
      PaperProps={{
        className: 'rounded-[10px] bg-[#3D404A]',
        style: {
          padding: '30px 50px 60px',
        },
      }}
    >
      <form onSubmit={handleSubmit} className="rounded-[10px] ">
        <header className="mt-[33px] text-center text-[15px] font-bold text-white">企业微信扫码</header>
        <div className="mt-[25px] flex items-center">
          <div
            className="relative flex-shrink-0"
            style={{
              width: 120,
              height: 120,
            }}
          >
            <img src="" alt="" />
          </div>
          <div style={{ width: 280, marginLeft: 20 }}>
            <div className={`text-[13px] text-white`}>
              请使用企业微信扫码登录绑定，首次绑定会有安全提示，通过验证码身份验证后，之后可以有稳定绑定状态。
            </div>
            <div className="mt-[25px] flex items-center">
              <input
                name="code"
                className={classNames(
                  'border-1',
                  'text-white',
                  'h-[45px]',
                  'w-[170px]',
                  'rounded-[5px]',
                  'border-solid',
                  'border-white',
                  'bg-[#3D404A]',
                  'pl-[10px]'
                )}
                placeholder="请输入验证码"
              />
              <Button
                type="submit"
                className="w-86px] ml-[25px] h-[45px] rounded-[5px] text-[15px]"
                variant="contained"
              >
                确认
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default WorkproBindDialog;
