import Cookies from 'js-cookie';
import { action, makeAutoObservable, observable } from 'mobx';

class Store {
  constructor() {
    makeAutoObservable(this);
    this.token = Cookies.get('token') ?? '';
    // this.token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDU0MjA1NTczNzQsInN1YiI6IjIifQ.JoWvbtzuAoz7Hb2g_kCYVBsvK2RdHcZcD5gvczfqGUw';
  }

  @observable
  token = '';

  @action
  setToken(token: string, disk = true) {
    this.token = token;
    // disk 为 true 才写入 cookie，否则仅作为临时状态。
    // 目的是强制绑定
    if (!disk) {
      return;
    }
    if (token) Cookies.set('token', token);
    else Cookies.remove('token');
  }

  @action
  logout() {
    this.token = '';
    Cookies.set('token', '');
  }

  @observable
  showLogin = false;

  @action
  setShowLogin(showLogin: boolean) {
    this.showLogin = showLogin;
    if (!showLogin && !Cookies.get('token')) {
      this.logout();
    }
  }

  @observable
  callbackUrl = '';

  @action
  setCallbackUrl(callbackUrl: string) {
    this.callbackUrl = callbackUrl;
  }

  @observable
  accountType: 'partner' | 'user' | undefined;

  @action
  setAccountType(accountType: 'partner' | 'user') {
    this.accountType = accountType;
  }

  @observable
  switchCode: 'open' | 'close' | undefined;

  @observable
  selfInfo: any;

  @action
  setSwitchCode(switchCode: 'open' | 'close') {
    this.switchCode = switchCode;
  }

  @observable
  selfLoaded = false;

  @action
  setSelfLoaded(selfLoaded: boolean) {
    this.selfLoaded = selfLoaded;
  }

  @action
  setSelfInfo(selfInfo: any) {
    this.selfInfo = selfInfo;
  }

  @observable
  alertText = '';

  @observable
  alertOpen = false

  @observable
  alertType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning' = 'neutral'

  @action
  openAlert(alertText: string, alertType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning' = 'neutral') {
    if (alertText) this.alertText = alertText;
    this.alertType = alertType;
    this.alertOpen = true;
  }

  @action
  closeAlert() {
    this.alertOpen = false;
    this.alertText = ''
    this.alertType = 'neutral'
  }
}

export default new Store();
