import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import iconAvatar from '../../assets/icons/icon-avatar.svg';
import iconHome from '../../assets/icons/icon-shiflow-logo.svg';
import iconSquare from '../../assets/icons/icon-square.svg';

export const Sidebar = ({ className = '' }) => {
  const { pathname } = useLocation();
  const isSquare = pathname === '/appstore'

  return (
    <header className={classNames('flex flex-shrink-0 h-full flex-col items-center w-[83px] pb-[30px] pt-[30px] bg-[#14151b]', className)}>
      <Link to="/">
        <img
          className="w-[35px] h-[35px]"
          src={iconHome}
          alt="logo"
        />
      </Link>
      {
        <Link
          to="/appstore"
          className={classNames('flex w-[71px] h-[75px] flex-shrink-0 flex-col items-center justify-center rounded-[8px] hover:bg-[#1f2028] mt-[20px] pb-[5px]', isSquare ? 'bg-[#1f2028]' : '')}
        >
          <img
            className="w-[30px] h-[57px]"
            src={iconSquare}
            alt="square"
          />
          <span className="text-[15px]/[28px] text-white mt-[-10px]">广场</span>
        </Link>
      }
      <Link
        to="/profile"
        className="mt-auto flex w-[71px] h-[75px] flex-shrink-0 flex-col items-center justify-center rounded-[8px] bg-[#1f2028]"
      >
        <img
          className="h-[28px] w-[28px] shrink-0 overflow-hidden object-contain object-center"
          src={iconAvatar}
          alt="me"
        />
        <span className="text-[15px]/[28px] text-white">我的</span>
      </Link>
    </header>
  );
};
