import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllApplications, getAuth } from '../../apis';
import AppCard from '../../components/app-card';
import Layout from '../../components/layout';
import { isDev } from '../../lib/constants';
import { isWeixin } from '../../lib/utils';
import { Sidebar } from '../apps-layout';
import './index.scss';

const AppIds: Record<string, string> = {
  shiflow: 'wxf1c89d9f6f68fd5d', // 识流正式
  shiflowtest: 'wxea895986e49d2c01', // 识流测试
}

function Appstore({ store }: any) {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const code = queryParams.get('code') || ''
  const state = queryParams.get('state') || ''
  const source = isDev ? 'shiflowtest' : 'shiflow'
  const appid = AppIds[source] || ''
  const navigate = useNavigate();
  const [apps, setApps] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [searchKey, setSearchKey] = useState('')

  useLayoutEffect(() => {
    if (isWeixin() && !store.token) {
      // 授权成功时，回调页面参数会带code参数
      if (code) {
        initAuth(code)
      }
      // 未授权时，跳转微信授权链接
      if (state !== 'authorized') {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=authorized#wechat_redirect`;
      }
    } else {
      initApps()
    }
  }, [])

  // 获取登录信息
  const initAuth = async (code: string) => {
    const { access_token } = await getAuth(code, source)
    store.setToken(access_token)
    initApps()
  }

  const initApps = async () => {
    const { list = [] } = await getAllApplications()
    setApps(list)
  }

  const onChat = (item: IAppInfo) => {
    navigate(`/chat/${item.id}`)
  }

  const onSearch = (event: any) => {
    const value = event?.target?.value ?? ''
    setInputValue(value)
  }

  const onKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setSearchKey(inputValue)
    }
  };

  return (
    <Layout className="wrapper-appstore">
      <Sidebar className="appstore-sidebar" />
      <div className="appstore">
        <Paper className="search-textfield" component="form">
          <InputBase
            className="search-input"
            placeholder="搜索AI应用"
            onInput={onSearch}
            onKeyDown={onKeyDown}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => setSearchKey(inputValue)}>
            <SearchIcon />
          </IconButton>
        </Paper>
        <div className="apps">
          {
            apps
              .filter((item: any) => item?.name?.includes(searchKey) || item?.introduction?.includes(searchKey))
              .map((item, index) =>
                <AppCard
                  className="app-item"
                  showTag={false}
                  key={index}
                  data={item as IAppInfo}
                  onClick={onChat.bind(null, item)}
                />
              )
          }
        </div>
      </div>
    </Layout>
  );
}

export default Appstore;