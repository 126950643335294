import { Avatar, Button, Pagination } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getFunds, getRecharges, getSelfFund } from '../../apis';
import Layout from '../../components/layout';
import { useWeixinLoginModal } from '../../components/weixin-bind-modal';
import { useQrcodeModal } from '../../hooks/qrcode-modal';
import { isDev } from '../../lib/constants';
import { Sidebar } from '../apps-layout';
import './index.scss';

const PAGE_SIZE = 15

function Chat({ store }: any) {
  const navigate = useNavigate();
  const [fund, setFund] = useState<Record<string, any>>({})
  const { id = '' } = useParams();
  const [curTabIndex, setCurTabIndex] = useState(0)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const [recharges, setRecharges] = useState([])
  const [funds, setFunds] = useState([])
  const [QrcodeModal, setQrcodeModalVisible] = useQrcodeModal({
    title: '微信扫码去充值',
    url: isDev ? 'http://gpts-test.shiflow.com/recharge?source=shiflowtest' : 'http://gpts.shiflow.com/recharge?source=shiflow',
  })
  const [WeixinLoginModal, setWeixinLoginModalVisible] = useWeixinLoginModal()

  useEffect(() => {
    getFund()
    getRechargesData(1)
  }, [])

  const onSwitchTab = (index: number) => {
    if (index === curTabIndex) return
    setCurTabIndex(index)
    setCount(1)
    if (index === 0) {
      getRechargesData(1)
    } else {
      getFundsData(1)
    }
  }

  const getFund = async () => {
    const fund = await getSelfFund()
    setFund(fund)
  }

  const getFundsData = async (page: number) => {
    setPage(page)
    const { items, total } = await getFunds(page, PAGE_SIZE)
    setFunds(items)
    setCount(Math.ceil(total / PAGE_SIZE))
  }

  const getRechargesData = async (page: number) => {
    setPage(page)
    const { items, total } = await getRecharges(page, PAGE_SIZE)
    setRecharges(items)
    setCount(Math.ceil(total / PAGE_SIZE))
  }

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    if (curTabIndex === 0) {
      getRechargesData(page)
    } else {
      getFundsData(page)
    }
  }

  const gotoApps = () => {
    navigate('/apps')
  }

  return (
    <Layout className="wrapper-profile">
      <Sidebar />
      <div className="profile">
        <div className="wrapper-board">
          <div className="wrapper-userinfo">
            <Avatar className="avatar" src={store?.selfInfo?.avatar} />
            <div className="nickname">{store?.selfInfo?.nickname}</div>
          </div>
          <div className="wrapper-balance">
            <div className="title">我的算力</div>
            <div className="balance">
              <div>{fund.sf_balance}</div>
              <Button
                style={{
                  backgroundColor: '#3e8ce2',
                  width: 106,
                  boxShadow: 'none',
                  height: 40,
                  borderRadius: 5,
                  border: 'solid 1px #fff',
                  fontSize: 15,
                }}
                variant="contained"
                onClick={() => {
                  if (!store.selfInfo?.is_bind_weixin) {
                    setWeixinLoginModalVisible(true);
                    return;
                  }
                  setQrcodeModalVisible(true)
                }}
              >
                <span
                  style={{
                    letterSpacing: 9,
                    textIndent: 9,
                  }}
                >
                  充值
                </span>
              </Button>
            </div>
          </div>
        </div>
        <div className="btn-creator" onClick={gotoApps}>创作者中心</div>
      </div>
      <div className="history">
        <div className="tabs">
          <div className={classNames('tab-item', { 'current': curTabIndex === 0 })} onClick={onSwitchTab.bind(null, 0)}>充值记录</div>
          <div className={classNames('tab-item', { 'current': curTabIndex === 1 })} onClick={onSwitchTab.bind(null, 1)}>消耗记录</div>
        </div>
        {
          curTabIndex === 0 && <>
            <div className="table-header">
              <div className="table-cell">充值时间</div>
              <div className="table-cell">充值算力</div>
              <div className="table-cell">充值金额</div>
            </div>
            {
              recharges.map((item: any) => <div className="table-row">
                <div className="table-cell">{item.created_at}</div>
                <div className="table-cell">{item.price * 100}</div>
                <div className="table-cell">¥{item.price / 100}</div>
              </div>)
            }
          </>
        }
        {
          curTabIndex === 1 && <>
            <div className="table-header">
              <div className="table-cell">消耗时间</div>
              <div className="table-cell">使用AI</div>
              <div className="table-cell">消费算力</div>
            </div>
            {
              funds.map((item: any) => <div className="table-row">
                <div className="table-cell">{item.created_at}</div>
                <div className="table-cell">{item.app_name}</div>
                <div className="table-cell">{item.outcome}</div>
              </div>)
            }
          </>
        }
        <div className="table-footer">
          <Pagination
            classes={{
              text: 'wrapper-pagination'
            }}
            count={count}
            shape="rounded"
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
      <QrcodeModal />
      <WeixinLoginModal />
    </Layout>
  );
}

export default Chat;