import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import './index.scss';

function ConfirmModal({
  open = false,
  title = '',
  onCancel = () => { },
  cancelText = '取消',
  onConfim = () => { },
  confirmText = '确认',
  pending = false,
}: any) {

  return (
    <Dialog classes={{ paper: 'confirm-dialog' }} open={open}>
      <DialogContent>
        <DialogContentText className="confirm-dialog-text">
          {title}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          classes={{
            outlined: 'confirm-dialog-button'
          }}
          onClick={onCancel}
          disabled={pending}
        >{cancelText}</Button>
        <Button
          variant="contained"
          onClick={onConfim}
          startIcon={pending && <CircularProgress size={20} color="inherit" />}
          disabled={pending}
        >{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
