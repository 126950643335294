import axios from 'axios';
import { API_HOST, isDev } from '../lib/constants';
import store from '../stores/index';

axios.defaults.baseURL = `http://${API_HOST}`
axios.interceptors.response.use((response) => {
  if (response?.data?.code === 401) {
    console.log('response', response);
    store.setToken('');
    const pathname = window.location.pathname;
    if (pathname !== '/') {
      store.setShowLogin(true);
    }
  }
  return response;
});

// 登录
export const login = async (username: string, password: string) => {
  try {
    const { data = {} } = await axios.post(
      `/shiflow/v1/login`,
      {
        username,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    console.info('wtf err', error);
    return {};
  }
};

// 合作商登录
export const loginPartner = async (username: string, password: string) => {
  try {
    const { data = {} } = await axios.post(
      `/shiflow/v1/corp/login`,
      {
        username,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    console.info('wtf err', error);
    return {};
  }
};

// 创建应用
export const createApplication = async (payload: any = {}) => {
  try {
    const { data = {} } = await axios.post('/shiflow/v1/applications', payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取应用
export const getApplication = async (appId: string) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${appId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取应用短信息
export const getApplicationShort = async (appId: string) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${appId}/short`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取应用广场列表
export const getAllApplications = async () => {
  try {
    const { data = {} } = await axios.get('/shiflow/v1/applications', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取对话记录
export const getChats = async (windowId: number, lastChatId: number, size: number = 20, isDebug: number) => {
  try {
    const { data = [] } = await axios.get(
      `/shiflow/v1/windows/${windowId}/chats?last_chat_id=${lastChatId}&size=${size}&is_debug=${isDebug}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${store.token}`,
        },
      }
    );
    if (data.code === 0) return data.body;
    return [];
  } catch (error) {
    return [];
  }
};

// 删除对话记录
export const removeChats = async (windowId: number) => {
  try {
    const { data = {} } = await axios.delete(`/shiflow/v1/windows/${windowId}/chats`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return true;
    return false;
  } catch (error) {
    return false;
  }
};

// 获取应用列表
export const getApplications = async () => {
  try {
    const { data = {} } = await axios.get('/shiflow/v1/self/applications', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 更新应用
export const updateApplication = async (appId: string, payload: any = {}) => {
  try {
    const { data = {} } = await axios.put(`/shiflow/v1/applications/${appId}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 发布应用
export const publishApplication = async (appId: string, payload: any = {}) => {
  try {
    const { data = {} } = await axios.put(`/shiflow/v1/applications/${appId}/publish`, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 删除应用
export const removeApplication = async (appId: string) => {
  try {
    const { data = {} } = await axios.delete(`/shiflow/v1/applications/${appId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return true;
    return false;
  } catch (error) {
    return false;
  }
};

// 上传文件
export const uploadFile = async (appId: string, file: string) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const { data = {} } = await axios.post(`/shiflow/v1/applications/${appId}/memories/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};
export const uploadAppIcon = async (appId: string, file: string) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const { data = {} } = await axios.put(`/shiflow/v1/applications/${appId}/icon`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 上传文件
export const removeFile = async (fileId: string) => {
  try {
    const { data = {} } = await axios.delete(`/shiflow/v1/memories/${fileId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return true;
    return false;
  } catch (error) {
    return false;
  }
};

// 获取文件列表
export const getAppFiles = async (appId: string, page: number, size: number = 20) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${appId}/memories?page=${page}&size=${size}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

export const getSelfApplications = async () => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/self/applications`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取邀请码列表
export const getAppCodes = async (appId: string, page: number, size: number = 20) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${appId}/codes?page=${page}&size=${size}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取用户列表
export const getAppAccounts = async (appId: string, page: number, size: number = 20) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${appId}/accounts?page=${page}&size=${size}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return data;
  } catch (error) {
    return {};
  }
};

// 禁用邀请码
export const removeRedeemCode = async (appId: string, codeId: number) => {
  try {
    const { data = {} } = await axios.delete(`/shiflow/v1/applications/${appId}/codes/${codeId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return data;
  } catch (error) {
    return {};
  }
};
export const removeCorpRedeemCode = async (codeId: number) => {
  try {
    const { data = {} } = await axios.delete(`/shiflow/v1/accounts/codes/${codeId}`, {
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
    return data;
  } catch (error) {
    return {};
  }
};

// 生成邀请码
export const generateRedeemCode = async (count: number, amount: number) => {
  try {
    const { data = {} } = await axios.post(
      '/shiflow/v1/accounts/codes',
      {
        count,
        amount,
      },
      {
        headers: {
          Authorization: `JWT ${store.token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return {};
  }
};

export const getSelfFund = async () => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/self/fund`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

export const getCorpSelfFund = async () => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/corp/self/fund`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

export const getAppFund = async (appid: string) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${appid}/fund`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

export const getCorpAppFund = async (appid: string) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/corp/applications/${appid}/fund`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 修改用户
export const updateAppAccount = async (appId: string, accountId: number, status: number) => {
  try {
    const { data = {} } = await axios.put(
      `/shiflow/v1/applications/${appId}/accounts/${accountId}`,
      {
        status,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `JWT ${store.token}`,
        },
      }
    );
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

export const getRedeemCodes = async ({
  page = 1,
  size = 20,
}: {
  page?: number;
  size?: number;
} = {}) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/accounts/codes`, {
      params: {
        page,
        size,
      },
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取公众号配置
export const getWeixinmpConfigs = async (appId: string) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${appId}/weixinmp/configs`, {
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 绑定公众号
export const updateWeixinmpConfigs = async (appId: string, config: Record<string, string>) => {
  try {
    const { data = {} } = await axios.put(
      `/shiflow/v1/applications/${appId}/weixinmp/configs`,
      config,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `JWT ${store.token}`,
        },
      }
    );
    return data
  } catch (error) {
    return { msg: '配置失败，请修改后重试' };
  }
};

// 解绑公众号
export const removeWeixinmpConfigs = async (appId: string) => {
  try {
    const { data = {} } = await axios.delete(`/shiflow/v1/applications/${appId}/weixinmp/unbind`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return true;
    return false;
  } catch (error) {
    return false;
  }
};

// 修改公众号配置
export const getAppSwitchs = async (appId: string) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${appId}/switchs`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

export const getApplicationAccountList = async (
  id: number | string,
  {
    page = 1,
    size = 20,
  }: {
    page?: number;
    size?: number;
  } = {}
) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${id}/accounts`, {
      params: {
        page,
        size,
      },
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};
export const getSelf = async () => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/self`, {
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

export const toggleAccountStatus = async ({ accountID, enable }: { accountID: string | number; enable: boolean }) => {
  try {
    const { data = {} } = await axios.put(
      `/shiflow/v1/accounts/${accountID}/status`,
      {
        status: Number(enable),
      },
      {
        headers: {
          Authorization: `JWT ${store.token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const disableRedeemCode = async (id: string) => {
  try {
    const { data = {} } = await axios.delete(`/shiflow/v1/applications/:id/codes/:code_id`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const getAccountList = async ({
  page = 1,
  size = 20,
}: {
  page?: number;
  size?: number;
} = {}) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/accounts`, {
      params: {
        page,
        size,
      },
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return Promise.reject(data.msg);
  } catch (error) {
    return {};
  }
};

export const getLoginQRCode = async () => {
  try {
    const resp = await axios.post(
      '/shiflow/v1/qrcode',
      {
        source: isDev ? 'shiflowtest' : 'shiflow',
      },
      {
        headers: {
          Authorization: `JWT ${store.token}`,
        },
      }
    );
    return resp.data
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getLoginStatus = async (nonce: string) => {
  try {
    const resp = await axios.get('/shiflow/v1/qrcode/login', {
      params: {
        nonce,
      },
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
    return resp.data;
  } catch (e) { }
};

// 获取支付配置
export const getPayConfig = async (source: string) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/pay/config?source=${source}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取公众号登录信息
export const getAuth = async (code: string, source: string) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/oauth?code=${code}&source=${source}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 支付
export const createPay = async (openid: string, amount: number, source: string) => {
  try {
    const { data = {} } = await axios.post(
      '/shiflow/v1/pay',
      {
        openid,
        amount,
        source,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${store.token}`,
        },
      }
    );
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取对话窗口列表
export const getWindows = async (appId: string, page: number, size: number = 20) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/${appId}/windows?page=${page}&size=${size}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 创建对话窗口
export const createWindow = async (appId: string) => {
  try {
    const { data = {} } = await axios.post(`/shiflow/v1/applications/${appId}/windows`, {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取消耗记录
export const getFunds = async (page: number, size: number = 20) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/applications/funds?page=${page}&size=${size}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};

// 获取消耗记录
export const getRecharges = async (page: number, size: number = 20) => {
  try {
    const { data = {} } = await axios.get(`/shiflow/v1/accounts/recharges?page=${page}&size=${size}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${store.token}`,
      },
    });
    if (data.code === 0) return data.body;
    return {};
  } catch (error) {
    return {};
  }
};
