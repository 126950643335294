import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import IconShiflow from '../../assets/icons/icon-shiflow.svg';
import PublishStatusTag from '../publish-status-tag';

export default function AppCard(
  props: PropsWithChildren<{
    className?: string;
    data: IAppInfo;
    showTag?: boolean;
    onClick?: () => void;
  }>
) {
  const {
    className,
    onClick,
    data: { icon, name, introduction, status, id },
    showTag = true,
  } = props;
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        className,
        `relative box-border flex h-[120px] items-center rounded-[10px] bg-[#3D404A] p-[27px] cursor-pointer`
      )}
      onClick={() => {
        if (onClick) {
          onClick();
          return
        }
        navigate(`/apps/${id}`);
      }}
    >
      <img
        className="mr-[37px] h-[70px] w-[70px] flex-shrink-0 rounded-full"
        src={icon || IconShiflow}
        alt=""
      />
      <div>
        <Typography className="bold text-[18px] text-white leading-6">{name}</Typography>
        <Typography className="mt-[1px] text-[15px] text-white leading-6 line-clamp-2">{introduction}</Typography>
        {showTag && <PublishStatusTag className="absolute right-[8px] top-[13px]" published={status === 'published'} />}
      </div>
    </div>
  );
}
