export const isDev = window.location.hostname === 'localhost' || window.location.hostname === 'gpts-test.shiflow.com'
export const API_HOST = isDev ? 'api-test.shiflow.com' : 'api.shiflow.com'

// 模型枚举值
export enum MODEL {
  GPT3 = 'gpt3.5',
  GPT4 = 'gpt4',
  WXYY = 'wxyy',
  WXYY_TURBO = 'wxyy_turbo',
  WXYY_FREE = 'wxyy_free',
  MINIMAX5 = 'minimax5',
  MINIMAX6 = 'minimax6',
  YUJUAI = 'yujuai',
  DOUBAO_LITE= 'doubao_lite',
  DOUBAO_PRO= 'doubao_pro',
  SEARCH = 'search',
  VOICE_ASK = 'tts',
  VOICE = 'tts',
  ELEVENLABS = 'elevenlabs',
  DALLE = 'dalle',
  MIDJOURNEY = 'midjourney',
  MIDJOURNEY_UPSCALE = 'midjourney_upscale',
  SD3 = 'sd3'
}

// 模型定价
export const MODEL_PRICE: Record<any, number> = {
  [MODEL.GPT3]: 200,
  [MODEL.GPT4]: 2500,
  [MODEL.WXYY]: 1400,
  [MODEL.WXYY_TURBO]: 100,
  [MODEL.WXYY_FREE]: 0,
  [MODEL.MINIMAX5]: 200,
  [MODEL.MINIMAX6]: 1100,
  [MODEL.YUJUAI]: 100,
  [MODEL.DOUBAO_LITE]: 15,
  [MODEL.DOUBAO_PRO]: 100,
  [MODEL.VOICE]: 1500,
  [MODEL.ELEVENLABS]: 20000,
  [MODEL.DALLE]: 4000,
  [MODEL.MIDJOURNEY]: 11000,
  [MODEL.MIDJOURNEY_UPSCALE]: 6000,
  [MODEL.SD3]: 5000,
}