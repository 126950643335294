import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

const RetButton: React.FC<{
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}> = ({ className, onClick, style }) => {
  const navigate = useNavigate();
  return (
    <i
      style={style}
      className={classNames(styles.iconReturn, 'cursor-pointer', className)}
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }
        navigate(-1);
      }}
    />
  );
};

export default RetButton;
