import { StyledEngineProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Sidebar } from './apps-navbar';

export default function AppsLayout({ store }: any) {
  return (
    <StyledEngineProvider injectFirst>
      <div className="wrapper-profile h-screen min-w-full">
        <section className="flex h-full flex-row bg-[#1F2028]">
          <Sidebar />
          <Outlet />
        </section>
      </div>
    </StyledEngineProvider>
  );
}
export { Sidebar } from './apps-navbar';
