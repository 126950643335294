import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

const AmountCard: React.FC<{
  amountText?: string;
  amount: number;
}> = ({ amount, amountText }) => {
  return (
    <div className={classNames(styles.ctn, 'border-radius-l')}>
      <span className={styles.title}>{amountText}</span>
      <div className={styles.revenue}>{amount}</div>
    </div>
  );
};

export default AmountCard;
