import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Pagination,
  StyledEngineProvider,
  Tooltip
} from '@mui/material';
import { useRequest } from 'ahooks';
import copy from 'copy-to-clipboard';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateRedeemCode, getRedeemCodes, removeCorpRedeemCode } from '../../../apis';
import AppTopNav from '../../../components/app-top-nav';
import './index.scss';

const StatusMap: any = {
  delete: '已停用',
  unused: '未使用',
  used: '已使用',
};

const PageSize = 20;

function Redeem({ store }: any) {
  const [page, setPage] = useState(1);
  const [showDisableRedeemDialog, setShowDisableRedeemDialog] = useState(false);
  const disableCodeRef = useRef<any>({});
  const [showGenerateDialog, setShowGenerateDialog] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [codeCount, setCodeCount] = useState<string>();
  const [pointsPerCode, setPointsPerCode] = useState<string>();
  const [validationTip, setValidationTip] = useState('');

  const { data, run } = useRequest(getRedeemCodes, {
    defaultParams: [
      {
        page: 1,
        size: PageSize,
      },
    ],
  });
  const load = (_page = page) =>
    run({
      page: _page,
      size: PageSize,
    });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    load(value);
  };

  const onGenerateCodeConfirm = async () => {
    if (isNaN(Number(codeCount))) {
      setValidationTip('充值码数量应该输入数字');
      return;
    }
    if (isNaN(Number(pointsPerCode))) {
      setValidationTip('算力额应该输入数字');
      return;
    }
    try {
      setUpdating(true);
      const resp = await generateRedeemCode(Number(codeCount), Number(pointsPerCode));
      const { code, msg = '' } = resp;
      if (code !== 0) {
        setValidationTip(msg);
      } else {
        load();
        setShowGenerateDialog(false);
      }
      setUpdating(false);
    } catch (e) {
      setUpdating(false);
    }
  };

  const onDisableConfirm = async () => {
    try {
      const { code, msg = '' } = await removeCorpRedeemCode(disableCodeRef.current?.item.id);
      if (code === 0) {
        load();
        store.openAlert('已停用邀请码')
      }
      setShowDisableRedeemDialog(false);
    } catch (e) {
      setShowDisableRedeemDialog(false);
    }
  };

  const handleCodeCountChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValidationTip('');
    setCodeCount(e.target.value);
  };

  const handlePointsChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValidationTip('');
    setPointsPerCode(e.target.value);
  };

  const onCopy = (text: string) => {
    try {
      copy(text);
      store.openAlert('复制成功')
    } catch {}
  };

  const labelClassName = 'text-[15px] text-white w-[135px]';
  const navigateTo = useNavigate();
  return (
    <>
      <div className="wrapper-redeem">
        <AppTopNav
          onBackClick={() => {
            navigateTo(-1);
          }}
        />
        <div className="header">
          <div className="left">
            <div className="title">邀请充值码</div>
            <div className="desc">
              您可自定邀请码价格，如29元、199元或1999元，并通过自有渠道销售。建议设置算力面额低于账户余额，避免余额不足影响使用。
            </div>
            <Button
              variant="contained"
              classes={{
                contained: 'button-upload',
              }}
              onClick={() => setShowGenerateDialog(true)}
            >
              生成充值邀请码
            </Button>
          </div>
        </div>
        <div
          className="table-header grid"
          style={{
            gridTemplateColumns: '2fr 2fr 3fr 3fr 2fr 2fr 2fr',
          }}
        >
          <div className="table-cell">邀请码</div>
          <div className="table-cell">使用状态</div>
          <div className="table-cell">生成时间</div>
          <div className="table-cell">激活时间</div>
          <div className="table-cell">用户名</div>
          <div className="table-cell">面额</div>
          <div className="table-cell">操作</div>
        </div>
        {data?.items?.map((item: any, index: number) => (
          <div
            className="table-row grid"
            key={index}
            style={{
              gridTemplateColumns: '2fr 2fr 3fr 3fr 2fr 2fr 2fr',
            }}
          >
            <div className="table-cell cursor-pointer" onClick={onCopy.bind(null, item.code)}>
              <Tooltip title="点击可复制" placement="right">
                {item.code}
              </Tooltip>
            </div>
            <div className="table-cell">{StatusMap[item.status]}</div>
            <div className="table-cell">{item.created_at}</div>
            <div className="table-cell">{item.register_at || '-'}</div>
            <div className="table-cell">{item.nickname || '-'}</div>
            <div className="table-cell">{item.amount}</div>
            <div className="table-cell">
              {item.status === 'unused' ? (
                <div
                  className="option-item"
                  onClick={() => {
                    setShowDisableRedeemDialog(true);
                    disableCodeRef.current.item = item;
                    disableCodeRef.current.index = index;
                  }}
                >
                  停用授权
                </div>
              ) : <>-</>}
            </div>
          </div>
        ))}
        <div className="table-footer">
          <Pagination
            classes={{
              text: 'wrapper-pagination',
            }}
            count={Math.ceil((data?.total || 0) / PageSize)}
            shape="rounded"
            page={page}
            onChange={handleChange}
          />
        </div>
        <Dialog classes={{ paper: 'confirm-dialog' }} open={showDisableRedeemDialog}>
          <DialogContent>
            <DialogContentText className="confirm-dialog-text">
              确定停用该邀请码？用户线下退款等场景可以使用该功能，停用后用户将无法使用本AI
            </DialogContentText>
          </DialogContent>
          <DialogActions className="py-[20px]">
            <Button
              variant="outlined"
              classes={{
                outlined: 'confirm-dialog-button',
              }}
              onClick={() => setShowDisableRedeemDialog(false)}
              disabled={updating}
            >
              取消
            </Button>
            <Button
              variant="contained"
              onClick={onDisableConfirm}
              startIcon={updating && <CircularProgress size={20} color="inherit" />}
              disabled={updating}
            >
              确认停用
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog classes={{ paper: 'confirm-dialog' }} open={showGenerateDialog}>
          <div className="px-[50px] py-[45px]">
            <DialogContentText className="bold text-[20px]" sx={{ color: '#fff' }}>
              生成邀请您充值码
            </DialogContentText>
            <div className="text-white">
              <div className="mt-[15px] flex items-center text-[15px]">
                <label className={labelClassName}>邀请充值码数量</label>
                <span className="box-border flex h-[44px] w-[155px] items-center rounded-[5px] border-[1px] border-solid border-white bg-[#1F2028]  px-[11px]">
                  <input
                    onChange={handleCodeCountChange}
                    className="my-[10px] block w-[60px] flex-1 bg-transparent  text-[15px] text-white"
                    type="text"
                  />
                  <span>个</span>
                </span>
              </div>
              <div className="mt-[15px] flex items-center text-[15px]">
                <label className={labelClassName}>邀请码算力额</label>
                <span className="box-border flex h-[44px] w-[155px] items-center rounded-[5px] border-[1px] border-solid border-white bg-[#1F2028]  px-[11px]">
                  <input
                    onChange={handlePointsChange}
                    className="my-[10px] block w-[60px] flex-1 bg-transparent  text-[15px] text-white"
                    type="text"
                  />
                  算力
                </span>
              </div>
            </div>
            <div className="flex h-[44px] items-center text-[13px] text-[#F44343]">
              {/**数量x面额应该小于账号总剩余算力{fund?.balance}*/}
              {validationTip}
            </div>
            <div className=" flex items-center justify-between">
              <Button
                variant="contained"
                className="h-[44px] w-[130px]"
                onClick={onGenerateCodeConfirm}
                startIcon={updating && <CircularProgress size={20} color="inherit" />}
                disabled={updating}
              >
                立即生成
              </Button>
              <Button
                variant="outlined"
                className="h-[44px] w-[130px] text-white"
                color={'inherit'}
                onClick={() => setShowGenerateDialog(false)}
                disabled={updating}
              >
                取消
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default function RedeemContainer(props: any) {
  return (
    <StyledEngineProvider injectFirst>
      <Redeem {...props} />
    </StyledEngineProvider>
  );
}
