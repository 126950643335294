import { Snackbar } from '@mui/joy';
import { Provider, inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.scss';
import { getSelf } from './apis';
import LoginModal from './components/login-modal';
import { isWeixin } from './lib/utils';
import User from './pages/account';
import AppsLayout from './pages/apps-layout';
import Applist, { AppList } from './pages/apps-layout/applist/index';
import WeixinBind from './pages/apps-layout/applist/weixin-bind';
import Redeem from './pages/apps-layout/redeem';
import Appstore from './pages/appstore/index';
import Chat from './pages/chat/index';
import Editor from './pages/editor/index';
import Home from './pages/home/index';
import Memory from './pages/memory/index';
import Profile from './pages/profile/index';
import Recharge from './pages/recharge/index';
import Shishang from './pages/shishang/index';
import Store from './stores/index';

// 首页
const HomePage = inject('store')(observer(Home));
const ShishangPage = inject('store')(observer(Shishang));
// 对话页
const ChatPage = inject('store')(observer(Chat));
// 创建应用页
const EditorPage = inject('store')(observer(Editor));
// 应用列表页
const ApplistPage = inject('store')(observer(Applist));
const ApplistMain = inject('store')(observer(AppList));
const AppstorePage = inject('store')(observer(Appstore));
// 知识库
const MemoryPage = inject('store')(observer(Memory));
const RedeemPage = inject('store')(observer(Redeem));
const UserPage = inject('store')(observer(User));
const WeixinBindPage = inject('store')(observer(WeixinBind));
const ProfilePage = inject('store')(observer(Profile));
// 充值页
const RechargePage = inject('store')(observer(Recharge));

const hostname = window.location.hostname;

function App() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname !== '/recharge' && !isWeixin()) {
      initSelf();
    }
  }, []);

  useEffect(() => {
    if (!Store.token && !['/', '/recharge'].includes(pathname) && !isWeixin()) {
      Store.setShowLogin(true);
      Store.setCallbackUrl(`${pathname}${search}`);
      navigate('/');
    }
  }, [navigate]);

  const getHomePage = () => {
    switch (hostname) {
      case 'shishang.shiflow.com':
      case 'ai.314live.net':
        // case 'localhost':
        document.title = 'AI视商'
        return <ShishangPage />;
      default:
        document.title = '识流AI'
        return <HomePage />;
    }
  };

  const initSelf = async () => {
    const self = await getSelf();
    const { account_type: accountType, switch_code: switchCode } = self;
    Store.setAccountType(accountType);
    Store.setSwitchCode(switchCode);
    Store.setSelfLoaded(true);
    Store.setSelfInfo(self);
  };

  return (
    <div className="App">
      {/* {
        (pathname === '/applist')
          && <div className="sidebar">
            <div className="sidebar-item"></div>
            <div className="sidebar-item"></div>
            <div className="sidebar-item"></div>
          </div>
      } */}
      <Provider store={Store}>
        <Routes>
          <Route path="/" element={getHomePage()} />
          <Route path="/chat/:id" element={<ChatPage />} />
          <Route path="/gpts/editor/:id?" element={<EditorPage />} />
          <Route path="/apps/:appid" element={<ApplistPage />} />
          <Route path="/account-management" element={<UserPage />} />
          <Route path="/apps" element={<AppsLayout />}>
            <Route path="" element={<ApplistMain />} />
            <Route path="redeem" element={<RedeemPage />} />
          </Route>
          <Route path="/weixin-bind/:appid" element={<WeixinBindPage />} />
          <Route path="/gpts/memory/:id" element={<MemoryPage />} />
          <Route path="/redeem" element={<RedeemPage />} />
          <Route path="/recharge" element={<RechargePage />} />
          <Route path="/appstore" element={<AppstorePage />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Routes>
      </Provider>
      <LoginModal />
      <Snackbar
        variant="soft"
        color={Store.alertType}
        open={Store.alertOpen}
        autoHideDuration={3000}
        onClose={() => Store.closeAlert()}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {Store.alertText}
      </Snackbar>
    </div>
  );
}

export default observer(App);
