import AddIcon from '@mui/icons-material/Add';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { createWindow, getApplicationShort, getAuth, getWindows } from '../../apis';
import ChatBase from '../../components/chat-base';
import HeadBar from '../../components/head-bar';
import Layout from '../../components/layout';
import { useDrawer } from '../../hooks/drawer';
import { isDev } from '../../lib/constants';
import { isWeixin } from '../../lib/utils';
import { Sidebar } from '../apps-layout';
import './index.scss';

const AppIds: Record<string, string> = {
  shiflow: 'wxf1c89d9f6f68fd5d', // 识流正式
  shiflowtest: 'wxea895986e49d2c01', // 识流测试
}

function Chat({ store }: any) {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const code = queryParams.get('code') || ''
  const state = queryParams.get('state') || ''
  const source = isDev ? 'shiflowtest' : 'shiflow'
  const appid = AppIds[source] || ''
  const [windows, setWindows] = useState<any[]>([])
  const [app, setApp] = useState({} as IAppInfo)
  const [curWindIndex, setCurWindIndex] = useState(0)
  const [chatVisible, setChatVisible] = useState(true)
  const { id = '' } = useParams();
  const [Drawer, setDrawerVisible] = useDrawer({})

  useLayoutEffect(() => {
    if (isWeixin() && !store.token) {
      // 授权成功时，回调页面参数会带code参数
      if (code) {
        initAuth(code)
      }
      // 未授权时，跳转微信授权链接
      if (state !== 'authorized') {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=authorized#wechat_redirect`;
      }
    } else {
      initApp()
      initWindows()
    }
  }, [])

  // 获取登录信息
  const initAuth = async (code: string) => {
    const { access_token } = await getAuth(code, source)
    store.setToken(access_token)
    initApp()
    initWindows()
  }

  const initWindows = async () => {
    const windows = await getWindows(id, 1, 100)
    if (!windows?.length) {
      onCreateWindow()
    }
    setWindows(windows)
  }

  const initApp = async () => {
    const app = await getApplicationShort(id)
    setApp(app)
  }

  const onChangeWindow = (index: number) => {
    setChatVisible(false)
    setCurWindIndex(index)
    setDrawerVisible(false)
    setTimeout(() => setChatVisible(true), 10)
  }

  const onCreateWindow = async () => {
    if (windows?.length && !windows[0]?.question) return
    const { id: windowId } = await createWindow(id)
    setWindows(windows => {
      return [
        {
          window_id: windowId,
          question: '',
        },
        ...windows,
      ]
    })
    onChangeWindow(0)
  }

  const onFirstAnswer = (answer: string) => {
    if (!windows?.[curWindIndex]?.question) {
      const newWindows = windows.map((wind, index) => {
        if (index === curWindIndex) wind.question = wind.question || answer
        return wind
      })
      setWindows(newWindows)
    }
  }

  const onShowChats = () => {
    console.log('onShowChats')
    setDrawerVisible(true)
  }

  return (
    <Layout
      className="wrapper-chat"
      sidebar={<Sidebar className="chat-sidebar" />}
      header={
        <HeadBar
          appInfo={app}
          showTag={false}
          rightButton={<div onClick={onShowChats}><DehazeIcon /></div>}
        />
      }
    >
      <div className="chats">
        <Button
          className="btn-create"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onCreateWindow}
        >新建对话</Button>
        {
          windows.map((wind: any, index: number) =>
            <div
              className={classNames('chat-item', { 'current': index === curWindIndex })}
              onClick={onChangeWindow.bind(null, index)}
            >
              <div className="chat-name">{wind.question || '新对话'}</div>
            </div>
          )
        }
      </div>
      {
        chatVisible && <ChatBase
          store={store}
          config={{
            id: app.id,
            name: app.name,
            icon: app.icon,
            window_id: windows[curWindIndex]?.window_id,
            introduction: app.introduction
          }}
          onFirstAnswer={onFirstAnswer}
        />
      }
      <Drawer>
        <div className="wrapper-chats-drawer">
          <div className="drawer-chats">
            <Button
              className="btn-create"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={onCreateWindow}
            >新建对话</Button>
            {
              windows.map((wind: any, index: number) =>
                <div
                  className={classNames('chat-item', { 'current': index === curWindIndex })}
                  onClick={onChangeWindow.bind(null, index)}
                >
                  <div className="chat-name">{wind.question || '新对话'}</div>
                </div>
              )
            }
          </div>
        </div>
      </Drawer>
    </Layout>
  );
}

export default Chat;
