import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getApplication } from '../../apis';
import IconShiflow from '../../assets/icons/icon-shiflow.svg';
import RetButton from '../ret-button';
import './index.scss';

function AppTopNav(props: { onBackClick?: () => void }) {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [application, setApplication] = useState<Record<string, any>>({});

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    const data = await getApplication(id);
    setApplication(data);
    console.log('data', data);
  };

  // 返回
  const onBack = () => {
    if (props.onBackClick) {
      props.onBackClick();
      return;
    }
    navigate('/');
  };

  return (
    <header className="wrapper-app-top-nav">
      <div className="wrapper-title">
        <RetButton onClick={onBack} className="button-back" />
        <div className="wrapper-appinfo">
          <img className="icon-logo" src={application.icon || IconShiflow} alt="" />
          <div className="appinfo">
            <div className="name">{application.name}</div>
            <div className="introduction">{application.introduction}</div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default AppTopNav;
