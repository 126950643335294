import React from 'react';
import { Button as MuiButton, createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';

type Props = React.PropsWithChildren<{
  width?: number | string;
  height?: number | string;
  type?: 'default' | 'primary';
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
  size?: 'default' | 'large';
}>;

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            backgroundColor: '#3d404a',
            color: '#fff',
            border: 'solid 1px #fff',
          }),
        }),
      },
    },
  },
  palette: {
    primary: {
      main: '#3e8ce2',
    },
    background: {
      default: '#fff',
    }
  }
});

const Button: React.FC<Props> = (props) => {
  const {
    width,
    height,
    type = 'default',
    onClick,
    className,
    style,
    size = 'default',
    children,
  } = props;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiButton
          onClick={onClick}
          style={{
            width,
            height,
            ...style
          }}
          variant="outlined"
          classes={{
            root: className,
            outlined: className,
          }}
          className={classNames(
            styles.button,
            { [styles.large]: size === 'large' },
            className
          )}>
          {children}
        </MuiButton>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Button;
