import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './index.scss';

function Shishang({ store }: any) {
  const navigate = useNavigate()

  const onProfile = () => {
    navigate('/apps');
  }

  return (
    <div className="wrapper-shishang">
      <img className="logo" src="https://shiflow-gpts.oss-cn-beijing.aliyuncs.com/shishang.gif" alt="" />
      <div className="wrapper-title">
        <h1 className="title">AI视商专业版管理后台</h1>
        {
          <Button className="button" variant="contained" onClick={onProfile}>
            {store.token ? '进入后台' : '立即登录'}
          </Button>
        }
      </div>
    </div>
  );
}

export default Shishang;
