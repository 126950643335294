import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, LinearProgress, Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAppFiles, removeFile, uploadFile } from '../../apis';
import AppTopNav from '../../components/app-top-nav';
import { formatBytes } from '../../lib/utils';
import './index.scss';

const pageSize = 20

function Memory({ store }: any) {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const [curFiles, setCurFiles] = useState<any[]>([])
  const [removeFileId, setRemoveFileId] = useState(0)
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false)
  const [removingFile, setRemovingFile] = useState(false)
  const [fileKey, setFileKey] = useState(0)
  const [usedSize, setUsedSize] = useState(0)
  const [maxSize, setMaxSize] = useState(100 * 1024 * 1024)

  useEffect(() => {
    getData()
  }, [])

  const getData = async (page: number = 1) => {
    const data = await getAppFiles(id, page, pageSize)
    const { items = [], total, used_storage_size, max_storage_size } = data ?? {}
    setCurFiles(items)
    setCount(Math.max(Math.ceil(total / pageSize), 1))
    setUsedSize(used_storage_size)
    setMaxSize(max_storage_size)
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    getData(value)
  };

  // 删除文件
  const onRemoveFile = async (id: number) => {
    setRemoveFileId(id)
    setRemoveConfirmOpen(true)
  }

  // 确认删除文件
  const onConfirmRemoveFile = async () => {
    if (removeFileId) {
      setRemovingFile(true)
      const success = await removeFile(String(removeFileId))
      setRemoveConfirmOpen(false)
      setRemovingFile(false)
      if (success) {
        getData(page)
      }
    }
  }

  // 上传文件
  const onUploadFile = async (e: any) => {
    if (id) {
      const file = e?.target?.files?.[0]
      const key = `file-${fileKey}`
      console.log('key', key)
      const curFile = {
        title: file?.name ?? '-',
        isUploading: true,
      }

      console.log('curFile', curFile)
      setCurFiles(curFiles => [curFile, ...curFiles])
      setFileKey(fileKey => fileKey + 1)
      const { file_id } = await uploadFile(id, file)
      if (file_id) {
        store.openAlert('上传文档成功')
        setPage(1)
        getData(1)
      }
    }
  }

  // 返回上一页
  const onBack = () => {
    navigate(-1)
  }

  return (
    <div className="wrapper-memory">
      <AppTopNav onBackClick={onBack} />
      <div className="header">
        <div className="left">
          <div className="title">知识库管理</div>
          <div className="desc">
            为你创建的AI上传知识库文档，AI可以学习文档的内容，当用户提问的时候，调用知识库的内容进行个性化的回复。支持网页链接、pdf、txt、docx等主流文件格式
          </div>
          <Button
            variant="contained"
            classes={{
              contained: 'button-upload',
            }}
          >
            上传文件
            <input
              type="file"
              onChange={onUploadFile}
              key={`file-${fileKey}`}
            />
          </Button>
        </div>
        <div className="right">
          <div className="memory-size">空间容量：{formatBytes(usedSize)}/{formatBytes(maxSize)}</div>
          <LinearProgress
            variant="determinate"
            classes={{
              root: 'progress'
            }}
            value={(usedSize / maxSize) * 100}
          />
        </div>
      </div>
      <div className="table-header">
        <div className="table-cell cell-title">文件名称</div>
        <div className="table-cell cell-status">文件状态</div>
        <div className="table-cell cell-type">文件类型</div>
        <div className="table-cell cell-size">文件大小</div>
        <div className="table-cell cell-date">上传时间</div>
        <div className="table-cell cell-option">操作</div>
      </div>
      {
        curFiles.map(file => <div className="table-row">
          <div className="table-cell cell-title">{file.title}</div>
          <div className="table-cell cell-status">{file.isUploading ? '解析中' : '解析完成'}</div>
          <div className="table-cell cell-type">{file.isUploading ? '--' : file.m_type?.toUpperCase()}</div>
          <div className="table-cell cell-size">{file.isUploading ? '--' : formatBytes(file.file_size)}</div>
          <div className="table-cell cell-date">{file.isUploading ? '--' : file.created_at}</div>
          <div className="table-cell cell-option">
            {
              file.isUploading
                ? '--'
                : <>
                  <a
                    className="option-item"
                    href={file.download_url}
                    rel="noreferrer"
                    target="_blank"
                  >查看</a>
                  <div
                    className="option-item"
                    onClick={onRemoveFile.bind(null, file.id)}
                  >删除</div>
                </>
            }
          </div>
        </div>)
      }
      <div className="table-footer">
        <Pagination
          classes={{ 
            text: 'wrapper-pagination'
          }}
          count={count}
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
      </div>
      <Dialog classes={{ paper: 'confirm-dialog' }} open={removeConfirmOpen}>
        <DialogContent>
          <DialogContentText className="confirm-dialog-text">
            确认删除？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            classes={{
              outlined: 'confirm-dialog-button'
            }}
            onClick={() => setRemoveConfirmOpen(false)}
            disabled={removingFile}
          >取消</Button>
          <Button
            variant="contained"
            onClick={onConfirmRemoveFile}
            startIcon={removingFile && <CircularProgress size={20} color="inherit" />}
            disabled={removingFile}
          >确认删除</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Memory;