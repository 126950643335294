import React from 'react';
import { Dialog } from '@mui/material';

const Contact: React.FC<{
  onCloseClick: () => void;
  text: string;
}> = ({ onCloseClick, text }) => {
  return (
    <div className="overflow-hidden rounded-[5px] bg-[#3D404A]">
      <header className="header relative flex w-[366px] flex-col items-center px-9 py-8">
        <header className="relative self-stretch text-base text-white" aria-label="Contact Information">
          {text}
        </header>
        <img
          loading="lazy"
          srcSet="/contact-qrcode.png"
          className="mt-12 aspect-[1.01] w-[149px] max-w-full object-contain object-center"
          alt="Image"
        />
        <button
          type="button"
          className="relative mt-11 cursor-pointer items-stretch justify-center rounded-md border border-solid border-white bg-zinc-700 px-14 py-4 text-center text-base text-white"
          aria-label="Cancel"
          role="button"
          onClick={onCloseClick}
        >
          取消
        </button>
      </header>
    </div>
  );
};

const ContactPopup: React.FC<{
  visible: boolean;
  text: string;
  onCloseClick: () => void;
}> = ({ text, visible, onCloseClick }) => {
  return (
    <Dialog
      open={visible}
      PaperProps={{
        className: 'bg-transparent',
      }}
    >
      <Contact text={text} onCloseClick={onCloseClick} />
    </Dialog>
  );
};

export default ContactPopup;

export const useContactPopup = (props?: any) => {
  const [visible, setVisible] = React.useState(false);
  const show = React.useCallback(() => {
    setVisible(true);
  }, []);
  const hide = React.useCallback(() => {
    setVisible(false);
  }, []);
  const __html = <ContactPopup onCloseClick={hide} visible={visible} {...props} />;
  return [
    __html,
    {
      show,
      hide,
    },
  ] as const;
};
